/**
 * @fileoverview gRPC-Web generated client stub for AgentMobileWeb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.1
// source: AgentMobileWeb.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.AgentMobileWeb = require('./AgentMobileWeb_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.AgentMobileWeb.AgentMobileWebSvcClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.AgentMobileWeb.AgentMobileWebSvcPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AgentMobileWeb.AgentRequest,
 *   !proto.AgentMobileWeb.AgentResponse>}
 */
const methodDescriptor_AgentMobileWebSvc_CreateAgents = new grpc.web.MethodDescriptor(
  '/AgentMobileWeb.AgentMobileWebSvc/CreateAgents',
  grpc.web.MethodType.UNARY,
  proto.AgentMobileWeb.AgentRequest,
  proto.AgentMobileWeb.AgentResponse,
  /**
   * @param {!proto.AgentMobileWeb.AgentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AgentMobileWeb.AgentResponse.deserializeBinary
);


/**
 * @param {!proto.AgentMobileWeb.AgentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AgentMobileWeb.AgentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AgentMobileWeb.AgentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AgentMobileWeb.AgentMobileWebSvcClient.prototype.createAgents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/CreateAgents',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_CreateAgents,
      callback);
};


/**
 * @param {!proto.AgentMobileWeb.AgentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AgentMobileWeb.AgentResponse>}
 *     Promise that resolves to the response
 */
proto.AgentMobileWeb.AgentMobileWebSvcPromiseClient.prototype.createAgents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/CreateAgents',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_CreateAgents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AgentMobileWeb.PasswordResetRequest,
 *   !proto.AgentMobileWeb.AgentResponse>}
 */
const methodDescriptor_AgentMobileWebSvc_PasswordResetAdminForAgents = new grpc.web.MethodDescriptor(
  '/AgentMobileWeb.AgentMobileWebSvc/PasswordResetAdminForAgents',
  grpc.web.MethodType.UNARY,
  proto.AgentMobileWeb.PasswordResetRequest,
  proto.AgentMobileWeb.AgentResponse,
  /**
   * @param {!proto.AgentMobileWeb.PasswordResetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AgentMobileWeb.AgentResponse.deserializeBinary
);


/**
 * @param {!proto.AgentMobileWeb.PasswordResetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AgentMobileWeb.AgentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AgentMobileWeb.AgentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AgentMobileWeb.AgentMobileWebSvcClient.prototype.passwordResetAdminForAgents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/PasswordResetAdminForAgents',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_PasswordResetAdminForAgents,
      callback);
};


/**
 * @param {!proto.AgentMobileWeb.PasswordResetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AgentMobileWeb.AgentResponse>}
 *     Promise that resolves to the response
 */
proto.AgentMobileWeb.AgentMobileWebSvcPromiseClient.prototype.passwordResetAdminForAgents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/PasswordResetAdminForAgents',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_PasswordResetAdminForAgents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AgentMobileWeb.AgentUpdateRequest,
 *   !proto.AgentMobileWeb.AgentResponse>}
 */
const methodDescriptor_AgentMobileWebSvc_UpdateAgent = new grpc.web.MethodDescriptor(
  '/AgentMobileWeb.AgentMobileWebSvc/UpdateAgent',
  grpc.web.MethodType.UNARY,
  proto.AgentMobileWeb.AgentUpdateRequest,
  proto.AgentMobileWeb.AgentResponse,
  /**
   * @param {!proto.AgentMobileWeb.AgentUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AgentMobileWeb.AgentResponse.deserializeBinary
);


/**
 * @param {!proto.AgentMobileWeb.AgentUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AgentMobileWeb.AgentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AgentMobileWeb.AgentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AgentMobileWeb.AgentMobileWebSvcClient.prototype.updateAgent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/UpdateAgent',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_UpdateAgent,
      callback);
};


/**
 * @param {!proto.AgentMobileWeb.AgentUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AgentMobileWeb.AgentResponse>}
 *     Promise that resolves to the response
 */
proto.AgentMobileWeb.AgentMobileWebSvcPromiseClient.prototype.updateAgent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/UpdateAgent',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_UpdateAgent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AgentMobileWeb.AgentEmptyRequest,
 *   !proto.AgentMobileWeb.ListOfAgentsResponse>}
 */
const methodDescriptor_AgentMobileWebSvc_GetListOfAgents = new grpc.web.MethodDescriptor(
  '/AgentMobileWeb.AgentMobileWebSvc/GetListOfAgents',
  grpc.web.MethodType.UNARY,
  proto.AgentMobileWeb.AgentEmptyRequest,
  proto.AgentMobileWeb.ListOfAgentsResponse,
  /**
   * @param {!proto.AgentMobileWeb.AgentEmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AgentMobileWeb.ListOfAgentsResponse.deserializeBinary
);


/**
 * @param {!proto.AgentMobileWeb.AgentEmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AgentMobileWeb.ListOfAgentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AgentMobileWeb.ListOfAgentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AgentMobileWeb.AgentMobileWebSvcClient.prototype.getListOfAgents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/GetListOfAgents',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_GetListOfAgents,
      callback);
};


/**
 * @param {!proto.AgentMobileWeb.AgentEmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AgentMobileWeb.ListOfAgentsResponse>}
 *     Promise that resolves to the response
 */
proto.AgentMobileWeb.AgentMobileWebSvcPromiseClient.prototype.getListOfAgents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/GetListOfAgents',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_GetListOfAgents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AgentMobileWeb.LoginRequest,
 *   !proto.AgentMobileWeb.LoginResponse>}
 */
const methodDescriptor_AgentMobileWebSvc_AgentLogin = new grpc.web.MethodDescriptor(
  '/AgentMobileWeb.AgentMobileWebSvc/AgentLogin',
  grpc.web.MethodType.UNARY,
  proto.AgentMobileWeb.LoginRequest,
  proto.AgentMobileWeb.LoginResponse,
  /**
   * @param {!proto.AgentMobileWeb.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AgentMobileWeb.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.AgentMobileWeb.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AgentMobileWeb.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AgentMobileWeb.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AgentMobileWeb.AgentMobileWebSvcClient.prototype.agentLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/AgentLogin',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_AgentLogin,
      callback);
};


/**
 * @param {!proto.AgentMobileWeb.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AgentMobileWeb.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.AgentMobileWeb.AgentMobileWebSvcPromiseClient.prototype.agentLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/AgentLogin',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_AgentLogin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AgentMobileWeb.ChangePinRequest,
 *   !proto.AgentMobileWeb.ChangePinResponse>}
 */
const methodDescriptor_AgentMobileWebSvc_AgentChangePin = new grpc.web.MethodDescriptor(
  '/AgentMobileWeb.AgentMobileWebSvc/AgentChangePin',
  grpc.web.MethodType.UNARY,
  proto.AgentMobileWeb.ChangePinRequest,
  proto.AgentMobileWeb.ChangePinResponse,
  /**
   * @param {!proto.AgentMobileWeb.ChangePinRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AgentMobileWeb.ChangePinResponse.deserializeBinary
);


/**
 * @param {!proto.AgentMobileWeb.ChangePinRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AgentMobileWeb.ChangePinResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AgentMobileWeb.ChangePinResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AgentMobileWeb.AgentMobileWebSvcClient.prototype.agentChangePin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/AgentChangePin',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_AgentChangePin,
      callback);
};


/**
 * @param {!proto.AgentMobileWeb.ChangePinRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AgentMobileWeb.ChangePinResponse>}
 *     Promise that resolves to the response
 */
proto.AgentMobileWeb.AgentMobileWebSvcPromiseClient.prototype.agentChangePin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/AgentChangePin',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_AgentChangePin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AgentMobileWeb.MemberCreatedByAgentRequest,
 *   !proto.AgentMobileWeb.MembersCreatedByAgentResponse>}
 */
const methodDescriptor_AgentMobileWebSvc_GetListOfGGCMembersCreatedByAgent = new grpc.web.MethodDescriptor(
  '/AgentMobileWeb.AgentMobileWebSvc/GetListOfGGCMembersCreatedByAgent',
  grpc.web.MethodType.UNARY,
  proto.AgentMobileWeb.MemberCreatedByAgentRequest,
  proto.AgentMobileWeb.MembersCreatedByAgentResponse,
  /**
   * @param {!proto.AgentMobileWeb.MemberCreatedByAgentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AgentMobileWeb.MembersCreatedByAgentResponse.deserializeBinary
);


/**
 * @param {!proto.AgentMobileWeb.MemberCreatedByAgentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AgentMobileWeb.MembersCreatedByAgentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AgentMobileWeb.MembersCreatedByAgentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AgentMobileWeb.AgentMobileWebSvcClient.prototype.getListOfGGCMembersCreatedByAgent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/GetListOfGGCMembersCreatedByAgent',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_GetListOfGGCMembersCreatedByAgent,
      callback);
};


/**
 * @param {!proto.AgentMobileWeb.MemberCreatedByAgentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AgentMobileWeb.MembersCreatedByAgentResponse>}
 *     Promise that resolves to the response
 */
proto.AgentMobileWeb.AgentMobileWebSvcPromiseClient.prototype.getListOfGGCMembersCreatedByAgent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/GetListOfGGCMembersCreatedByAgent',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_GetListOfGGCMembersCreatedByAgent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AgentMobileWeb.TransactionListAgentRequest,
 *   !proto.AgentMobileWeb.TransactionListResponse>}
 */
const methodDescriptor_AgentMobileWebSvc_GetTransactionListForAgent = new grpc.web.MethodDescriptor(
  '/AgentMobileWeb.AgentMobileWebSvc/GetTransactionListForAgent',
  grpc.web.MethodType.UNARY,
  proto.AgentMobileWeb.TransactionListAgentRequest,
  proto.AgentMobileWeb.TransactionListResponse,
  /**
   * @param {!proto.AgentMobileWeb.TransactionListAgentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AgentMobileWeb.TransactionListResponse.deserializeBinary
);


/**
 * @param {!proto.AgentMobileWeb.TransactionListAgentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AgentMobileWeb.TransactionListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AgentMobileWeb.TransactionListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AgentMobileWeb.AgentMobileWebSvcClient.prototype.getTransactionListForAgent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/GetTransactionListForAgent',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_GetTransactionListForAgent,
      callback);
};


/**
 * @param {!proto.AgentMobileWeb.TransactionListAgentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AgentMobileWeb.TransactionListResponse>}
 *     Promise that resolves to the response
 */
proto.AgentMobileWeb.AgentMobileWebSvcPromiseClient.prototype.getTransactionListForAgent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/GetTransactionListForAgent',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_GetTransactionListForAgent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AgentMobileWeb.DonationCollectedRequest,
 *   !proto.AgentMobileWeb.DonationCollectedResponse>}
 */
const methodDescriptor_AgentMobileWebSvc_GetTotalDonationCollectedByAgent = new grpc.web.MethodDescriptor(
  '/AgentMobileWeb.AgentMobileWebSvc/GetTotalDonationCollectedByAgent',
  grpc.web.MethodType.UNARY,
  proto.AgentMobileWeb.DonationCollectedRequest,
  proto.AgentMobileWeb.DonationCollectedResponse,
  /**
   * @param {!proto.AgentMobileWeb.DonationCollectedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AgentMobileWeb.DonationCollectedResponse.deserializeBinary
);


/**
 * @param {!proto.AgentMobileWeb.DonationCollectedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AgentMobileWeb.DonationCollectedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AgentMobileWeb.DonationCollectedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AgentMobileWeb.AgentMobileWebSvcClient.prototype.getTotalDonationCollectedByAgent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/GetTotalDonationCollectedByAgent',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_GetTotalDonationCollectedByAgent,
      callback);
};


/**
 * @param {!proto.AgentMobileWeb.DonationCollectedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AgentMobileWeb.DonationCollectedResponse>}
 *     Promise that resolves to the response
 */
proto.AgentMobileWeb.AgentMobileWebSvcPromiseClient.prototype.getTotalDonationCollectedByAgent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/GetTotalDonationCollectedByAgent',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_GetTotalDonationCollectedByAgent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AgentMobileWeb.TotalTransactionCommissionRequest,
 *   !proto.AgentMobileWeb.TotalTransactionCommissionResponse>}
 */
const methodDescriptor_AgentMobileWebSvc_GetListOfAgentsAndTotalTransactionAndCommissions = new grpc.web.MethodDescriptor(
  '/AgentMobileWeb.AgentMobileWebSvc/GetListOfAgentsAndTotalTransactionAndCommissions',
  grpc.web.MethodType.UNARY,
  proto.AgentMobileWeb.TotalTransactionCommissionRequest,
  proto.AgentMobileWeb.TotalTransactionCommissionResponse,
  /**
   * @param {!proto.AgentMobileWeb.TotalTransactionCommissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AgentMobileWeb.TotalTransactionCommissionResponse.deserializeBinary
);


/**
 * @param {!proto.AgentMobileWeb.TotalTransactionCommissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AgentMobileWeb.TotalTransactionCommissionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AgentMobileWeb.TotalTransactionCommissionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AgentMobileWeb.AgentMobileWebSvcClient.prototype.getListOfAgentsAndTotalTransactionAndCommissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/GetListOfAgentsAndTotalTransactionAndCommissions',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_GetListOfAgentsAndTotalTransactionAndCommissions,
      callback);
};


/**
 * @param {!proto.AgentMobileWeb.TotalTransactionCommissionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AgentMobileWeb.TotalTransactionCommissionResponse>}
 *     Promise that resolves to the response
 */
proto.AgentMobileWeb.AgentMobileWebSvcPromiseClient.prototype.getListOfAgentsAndTotalTransactionAndCommissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/GetListOfAgentsAndTotalTransactionAndCommissions',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_GetListOfAgentsAndTotalTransactionAndCommissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AgentMobileWeb.TotalTransactionCommissionRequest,
 *   !proto.AgentMobileWeb.TransactionCommissionDetailResponse>}
 */
const methodDescriptor_AgentMobileWebSvc_GetListOfAgentsAndTotalTransactionAndCommissionsDetails = new grpc.web.MethodDescriptor(
  '/AgentMobileWeb.AgentMobileWebSvc/GetListOfAgentsAndTotalTransactionAndCommissionsDetails',
  grpc.web.MethodType.UNARY,
  proto.AgentMobileWeb.TotalTransactionCommissionRequest,
  proto.AgentMobileWeb.TransactionCommissionDetailResponse,
  /**
   * @param {!proto.AgentMobileWeb.TotalTransactionCommissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AgentMobileWeb.TransactionCommissionDetailResponse.deserializeBinary
);


/**
 * @param {!proto.AgentMobileWeb.TotalTransactionCommissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AgentMobileWeb.TransactionCommissionDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AgentMobileWeb.TransactionCommissionDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AgentMobileWeb.AgentMobileWebSvcClient.prototype.getListOfAgentsAndTotalTransactionAndCommissionsDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/GetListOfAgentsAndTotalTransactionAndCommissionsDetails',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_GetListOfAgentsAndTotalTransactionAndCommissionsDetails,
      callback);
};


/**
 * @param {!proto.AgentMobileWeb.TotalTransactionCommissionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AgentMobileWeb.TransactionCommissionDetailResponse>}
 *     Promise that resolves to the response
 */
proto.AgentMobileWeb.AgentMobileWebSvcPromiseClient.prototype.getListOfAgentsAndTotalTransactionAndCommissionsDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AgentMobileWeb.AgentMobileWebSvc/GetListOfAgentsAndTotalTransactionAndCommissionsDetails',
      request,
      metadata || {},
      methodDescriptor_AgentMobileWebSvc_GetListOfAgentsAndTotalTransactionAndCommissionsDetails);
};


module.exports = proto.AgentMobileWeb;

