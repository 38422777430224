// source: CustomerSetups.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.CustomerSetups.AgeRangeData', null, global);
goog.exportSymbol('proto.CustomerSetups.ConstituenciesResponse', null, global);
goog.exportSymbol('proto.CustomerSetups.ConstituencyData', null, global);
goog.exportSymbol('proto.CustomerSetups.EmptySetupRequest', null, global);
goog.exportSymbol('proto.CustomerSetups.ListOfAgeRanges', null, global);
goog.exportSymbol('proto.CustomerSetups.ListOfPaymentMethods', null, global);
goog.exportSymbol('proto.CustomerSetups.ListOfSetupCountryResponse', null, global);
goog.exportSymbol('proto.CustomerSetups.PaymentMethod', null, global);
goog.exportSymbol('proto.CustomerSetups.RegionData', null, global);
goog.exportSymbol('proto.CustomerSetups.RegionResponse', null, global);
goog.exportSymbol('proto.CustomerSetups.SetupCountry', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CustomerSetups.EmptySetupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CustomerSetups.EmptySetupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CustomerSetups.EmptySetupRequest.displayName = 'proto.CustomerSetups.EmptySetupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CustomerSetups.RegionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CustomerSetups.RegionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CustomerSetups.RegionData.displayName = 'proto.CustomerSetups.RegionData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CustomerSetups.RegionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CustomerSetups.RegionResponse.repeatedFields_, null);
};
goog.inherits(proto.CustomerSetups.RegionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CustomerSetups.RegionResponse.displayName = 'proto.CustomerSetups.RegionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CustomerSetups.ConstituencyData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CustomerSetups.ConstituencyData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CustomerSetups.ConstituencyData.displayName = 'proto.CustomerSetups.ConstituencyData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CustomerSetups.ConstituenciesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CustomerSetups.ConstituenciesResponse.repeatedFields_, null);
};
goog.inherits(proto.CustomerSetups.ConstituenciesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CustomerSetups.ConstituenciesResponse.displayName = 'proto.CustomerSetups.ConstituenciesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CustomerSetups.AgeRangeData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CustomerSetups.AgeRangeData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CustomerSetups.AgeRangeData.displayName = 'proto.CustomerSetups.AgeRangeData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CustomerSetups.ListOfAgeRanges = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CustomerSetups.ListOfAgeRanges.repeatedFields_, null);
};
goog.inherits(proto.CustomerSetups.ListOfAgeRanges, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CustomerSetups.ListOfAgeRanges.displayName = 'proto.CustomerSetups.ListOfAgeRanges';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CustomerSetups.PaymentMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CustomerSetups.PaymentMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CustomerSetups.PaymentMethod.displayName = 'proto.CustomerSetups.PaymentMethod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CustomerSetups.ListOfPaymentMethods = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CustomerSetups.ListOfPaymentMethods.repeatedFields_, null);
};
goog.inherits(proto.CustomerSetups.ListOfPaymentMethods, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CustomerSetups.ListOfPaymentMethods.displayName = 'proto.CustomerSetups.ListOfPaymentMethods';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CustomerSetups.SetupCountry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CustomerSetups.SetupCountry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CustomerSetups.SetupCountry.displayName = 'proto.CustomerSetups.SetupCountry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CustomerSetups.ListOfSetupCountryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CustomerSetups.ListOfSetupCountryResponse.repeatedFields_, null);
};
goog.inherits(proto.CustomerSetups.ListOfSetupCountryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CustomerSetups.ListOfSetupCountryResponse.displayName = 'proto.CustomerSetups.ListOfSetupCountryResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CustomerSetups.EmptySetupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CustomerSetups.EmptySetupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CustomerSetups.EmptySetupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.EmptySetupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CustomerSetups.EmptySetupRequest}
 */
proto.CustomerSetups.EmptySetupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CustomerSetups.EmptySetupRequest;
  return proto.CustomerSetups.EmptySetupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CustomerSetups.EmptySetupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CustomerSetups.EmptySetupRequest}
 */
proto.CustomerSetups.EmptySetupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CustomerSetups.EmptySetupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CustomerSetups.EmptySetupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CustomerSetups.EmptySetupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.EmptySetupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CustomerSetups.RegionData.prototype.toObject = function(opt_includeInstance) {
  return proto.CustomerSetups.RegionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CustomerSetups.RegionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.RegionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    regionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    regionname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CustomerSetups.RegionData}
 */
proto.CustomerSetups.RegionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CustomerSetups.RegionData;
  return proto.CustomerSetups.RegionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CustomerSetups.RegionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CustomerSetups.RegionData}
 */
proto.CustomerSetups.RegionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CustomerSetups.RegionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CustomerSetups.RegionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CustomerSetups.RegionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.RegionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegionname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string regionId = 1;
 * @return {string}
 */
proto.CustomerSetups.RegionData.prototype.getRegionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CustomerSetups.RegionData} returns this
 */
proto.CustomerSetups.RegionData.prototype.setRegionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string regionName = 2;
 * @return {string}
 */
proto.CustomerSetups.RegionData.prototype.getRegionname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CustomerSetups.RegionData} returns this
 */
proto.CustomerSetups.RegionData.prototype.setRegionname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.CustomerSetups.RegionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CustomerSetups.RegionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CustomerSetups.RegionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CustomerSetups.RegionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.RegionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    regionsList: jspb.Message.toObjectList(msg.getRegionsList(),
    proto.CustomerSetups.RegionData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CustomerSetups.RegionResponse}
 */
proto.CustomerSetups.RegionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CustomerSetups.RegionResponse;
  return proto.CustomerSetups.RegionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CustomerSetups.RegionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CustomerSetups.RegionResponse}
 */
proto.CustomerSetups.RegionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.CustomerSetups.RegionData;
      reader.readMessage(value,proto.CustomerSetups.RegionData.deserializeBinaryFromReader);
      msg.addRegions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CustomerSetups.RegionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CustomerSetups.RegionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CustomerSetups.RegionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.RegionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.CustomerSetups.RegionData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RegionData regions = 1;
 * @return {!Array<!proto.CustomerSetups.RegionData>}
 */
proto.CustomerSetups.RegionResponse.prototype.getRegionsList = function() {
  return /** @type{!Array<!proto.CustomerSetups.RegionData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.CustomerSetups.RegionData, 1));
};


/**
 * @param {!Array<!proto.CustomerSetups.RegionData>} value
 * @return {!proto.CustomerSetups.RegionResponse} returns this
*/
proto.CustomerSetups.RegionResponse.prototype.setRegionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.CustomerSetups.RegionData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.CustomerSetups.RegionData}
 */
proto.CustomerSetups.RegionResponse.prototype.addRegions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.CustomerSetups.RegionData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CustomerSetups.RegionResponse} returns this
 */
proto.CustomerSetups.RegionResponse.prototype.clearRegionsList = function() {
  return this.setRegionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CustomerSetups.ConstituencyData.prototype.toObject = function(opt_includeInstance) {
  return proto.CustomerSetups.ConstituencyData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CustomerSetups.ConstituencyData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.ConstituencyData.toObject = function(includeInstance, msg) {
  var f, obj = {
    constituencyid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    constituencyname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    regionid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CustomerSetups.ConstituencyData}
 */
proto.CustomerSetups.ConstituencyData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CustomerSetups.ConstituencyData;
  return proto.CustomerSetups.ConstituencyData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CustomerSetups.ConstituencyData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CustomerSetups.ConstituencyData}
 */
proto.CustomerSetups.ConstituencyData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConstituencyid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConstituencyname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CustomerSetups.ConstituencyData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CustomerSetups.ConstituencyData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CustomerSetups.ConstituencyData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.ConstituencyData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConstituencyid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConstituencyname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRegionid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string constituencyId = 1;
 * @return {string}
 */
proto.CustomerSetups.ConstituencyData.prototype.getConstituencyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CustomerSetups.ConstituencyData} returns this
 */
proto.CustomerSetups.ConstituencyData.prototype.setConstituencyid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string constituencyName = 2;
 * @return {string}
 */
proto.CustomerSetups.ConstituencyData.prototype.getConstituencyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CustomerSetups.ConstituencyData} returns this
 */
proto.CustomerSetups.ConstituencyData.prototype.setConstituencyname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string regionId = 3;
 * @return {string}
 */
proto.CustomerSetups.ConstituencyData.prototype.getRegionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.CustomerSetups.ConstituencyData} returns this
 */
proto.CustomerSetups.ConstituencyData.prototype.setRegionid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.CustomerSetups.ConstituenciesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CustomerSetups.ConstituenciesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CustomerSetups.ConstituenciesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CustomerSetups.ConstituenciesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.ConstituenciesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    constituenciesList: jspb.Message.toObjectList(msg.getConstituenciesList(),
    proto.CustomerSetups.ConstituencyData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CustomerSetups.ConstituenciesResponse}
 */
proto.CustomerSetups.ConstituenciesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CustomerSetups.ConstituenciesResponse;
  return proto.CustomerSetups.ConstituenciesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CustomerSetups.ConstituenciesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CustomerSetups.ConstituenciesResponse}
 */
proto.CustomerSetups.ConstituenciesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.CustomerSetups.ConstituencyData;
      reader.readMessage(value,proto.CustomerSetups.ConstituencyData.deserializeBinaryFromReader);
      msg.addConstituencies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CustomerSetups.ConstituenciesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CustomerSetups.ConstituenciesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CustomerSetups.ConstituenciesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.ConstituenciesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConstituenciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.CustomerSetups.ConstituencyData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConstituencyData constituencies = 1;
 * @return {!Array<!proto.CustomerSetups.ConstituencyData>}
 */
proto.CustomerSetups.ConstituenciesResponse.prototype.getConstituenciesList = function() {
  return /** @type{!Array<!proto.CustomerSetups.ConstituencyData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.CustomerSetups.ConstituencyData, 1));
};


/**
 * @param {!Array<!proto.CustomerSetups.ConstituencyData>} value
 * @return {!proto.CustomerSetups.ConstituenciesResponse} returns this
*/
proto.CustomerSetups.ConstituenciesResponse.prototype.setConstituenciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.CustomerSetups.ConstituencyData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.CustomerSetups.ConstituencyData}
 */
proto.CustomerSetups.ConstituenciesResponse.prototype.addConstituencies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.CustomerSetups.ConstituencyData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CustomerSetups.ConstituenciesResponse} returns this
 */
proto.CustomerSetups.ConstituenciesResponse.prototype.clearConstituenciesList = function() {
  return this.setConstituenciesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CustomerSetups.AgeRangeData.prototype.toObject = function(opt_includeInstance) {
  return proto.CustomerSetups.AgeRangeData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CustomerSetups.AgeRangeData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.AgeRangeData.toObject = function(includeInstance, msg) {
  var f, obj = {
    agerangeid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    agerangename: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CustomerSetups.AgeRangeData}
 */
proto.CustomerSetups.AgeRangeData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CustomerSetups.AgeRangeData;
  return proto.CustomerSetups.AgeRangeData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CustomerSetups.AgeRangeData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CustomerSetups.AgeRangeData}
 */
proto.CustomerSetups.AgeRangeData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgerangeid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgerangename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CustomerSetups.AgeRangeData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CustomerSetups.AgeRangeData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CustomerSetups.AgeRangeData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.AgeRangeData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgerangeid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAgerangename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ageRangeId = 1;
 * @return {string}
 */
proto.CustomerSetups.AgeRangeData.prototype.getAgerangeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CustomerSetups.AgeRangeData} returns this
 */
proto.CustomerSetups.AgeRangeData.prototype.setAgerangeid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ageRangeName = 2;
 * @return {string}
 */
proto.CustomerSetups.AgeRangeData.prototype.getAgerangename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CustomerSetups.AgeRangeData} returns this
 */
proto.CustomerSetups.AgeRangeData.prototype.setAgerangename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.CustomerSetups.ListOfAgeRanges.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CustomerSetups.ListOfAgeRanges.prototype.toObject = function(opt_includeInstance) {
  return proto.CustomerSetups.ListOfAgeRanges.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CustomerSetups.ListOfAgeRanges} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.ListOfAgeRanges.toObject = function(includeInstance, msg) {
  var f, obj = {
    agerangesList: jspb.Message.toObjectList(msg.getAgerangesList(),
    proto.CustomerSetups.AgeRangeData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CustomerSetups.ListOfAgeRanges}
 */
proto.CustomerSetups.ListOfAgeRanges.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CustomerSetups.ListOfAgeRanges;
  return proto.CustomerSetups.ListOfAgeRanges.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CustomerSetups.ListOfAgeRanges} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CustomerSetups.ListOfAgeRanges}
 */
proto.CustomerSetups.ListOfAgeRanges.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.CustomerSetups.AgeRangeData;
      reader.readMessage(value,proto.CustomerSetups.AgeRangeData.deserializeBinaryFromReader);
      msg.addAgeranges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CustomerSetups.ListOfAgeRanges.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CustomerSetups.ListOfAgeRanges.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CustomerSetups.ListOfAgeRanges} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.ListOfAgeRanges.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgerangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.CustomerSetups.AgeRangeData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AgeRangeData ageRanges = 1;
 * @return {!Array<!proto.CustomerSetups.AgeRangeData>}
 */
proto.CustomerSetups.ListOfAgeRanges.prototype.getAgerangesList = function() {
  return /** @type{!Array<!proto.CustomerSetups.AgeRangeData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.CustomerSetups.AgeRangeData, 1));
};


/**
 * @param {!Array<!proto.CustomerSetups.AgeRangeData>} value
 * @return {!proto.CustomerSetups.ListOfAgeRanges} returns this
*/
proto.CustomerSetups.ListOfAgeRanges.prototype.setAgerangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.CustomerSetups.AgeRangeData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.CustomerSetups.AgeRangeData}
 */
proto.CustomerSetups.ListOfAgeRanges.prototype.addAgeranges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.CustomerSetups.AgeRangeData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CustomerSetups.ListOfAgeRanges} returns this
 */
proto.CustomerSetups.ListOfAgeRanges.prototype.clearAgerangesList = function() {
  return this.setAgerangesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CustomerSetups.PaymentMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.CustomerSetups.PaymentMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CustomerSetups.PaymentMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.PaymentMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentmethodid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentmethodname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    paymentmethodtype: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CustomerSetups.PaymentMethod}
 */
proto.CustomerSetups.PaymentMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CustomerSetups.PaymentMethod;
  return proto.CustomerSetups.PaymentMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CustomerSetups.PaymentMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CustomerSetups.PaymentMethod}
 */
proto.CustomerSetups.PaymentMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentmethodid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentmethodname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentmethodtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CustomerSetups.PaymentMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CustomerSetups.PaymentMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CustomerSetups.PaymentMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.PaymentMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentmethodid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentmethodname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPaymentmethodtype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string paymentMethodId = 1;
 * @return {string}
 */
proto.CustomerSetups.PaymentMethod.prototype.getPaymentmethodid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CustomerSetups.PaymentMethod} returns this
 */
proto.CustomerSetups.PaymentMethod.prototype.setPaymentmethodid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string PaymentMethodName = 2;
 * @return {string}
 */
proto.CustomerSetups.PaymentMethod.prototype.getPaymentmethodname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CustomerSetups.PaymentMethod} returns this
 */
proto.CustomerSetups.PaymentMethod.prototype.setPaymentmethodname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string paymentMethodType = 3;
 * @return {string}
 */
proto.CustomerSetups.PaymentMethod.prototype.getPaymentmethodtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.CustomerSetups.PaymentMethod} returns this
 */
proto.CustomerSetups.PaymentMethod.prototype.setPaymentmethodtype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.CustomerSetups.ListOfPaymentMethods.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CustomerSetups.ListOfPaymentMethods.prototype.toObject = function(opt_includeInstance) {
  return proto.CustomerSetups.ListOfPaymentMethods.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CustomerSetups.ListOfPaymentMethods} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.ListOfPaymentMethods.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymethodlistList: jspb.Message.toObjectList(msg.getPaymethodlistList(),
    proto.CustomerSetups.PaymentMethod.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CustomerSetups.ListOfPaymentMethods}
 */
proto.CustomerSetups.ListOfPaymentMethods.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CustomerSetups.ListOfPaymentMethods;
  return proto.CustomerSetups.ListOfPaymentMethods.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CustomerSetups.ListOfPaymentMethods} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CustomerSetups.ListOfPaymentMethods}
 */
proto.CustomerSetups.ListOfPaymentMethods.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.CustomerSetups.PaymentMethod;
      reader.readMessage(value,proto.CustomerSetups.PaymentMethod.deserializeBinaryFromReader);
      msg.addPaymethodlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CustomerSetups.ListOfPaymentMethods.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CustomerSetups.ListOfPaymentMethods.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CustomerSetups.ListOfPaymentMethods} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.ListOfPaymentMethods.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymethodlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.CustomerSetups.PaymentMethod.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PaymentMethod payMethodList = 1;
 * @return {!Array<!proto.CustomerSetups.PaymentMethod>}
 */
proto.CustomerSetups.ListOfPaymentMethods.prototype.getPaymethodlistList = function() {
  return /** @type{!Array<!proto.CustomerSetups.PaymentMethod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.CustomerSetups.PaymentMethod, 1));
};


/**
 * @param {!Array<!proto.CustomerSetups.PaymentMethod>} value
 * @return {!proto.CustomerSetups.ListOfPaymentMethods} returns this
*/
proto.CustomerSetups.ListOfPaymentMethods.prototype.setPaymethodlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.CustomerSetups.PaymentMethod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.CustomerSetups.PaymentMethod}
 */
proto.CustomerSetups.ListOfPaymentMethods.prototype.addPaymethodlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.CustomerSetups.PaymentMethod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CustomerSetups.ListOfPaymentMethods} returns this
 */
proto.CustomerSetups.ListOfPaymentMethods.prototype.clearPaymethodlistList = function() {
  return this.setPaymethodlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CustomerSetups.SetupCountry.prototype.toObject = function(opt_includeInstance) {
  return proto.CustomerSetups.SetupCountry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CustomerSetups.SetupCountry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.SetupCountry.toObject = function(includeInstance, msg) {
  var f, obj = {
    countrycode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    countryname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    countrydialcode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CustomerSetups.SetupCountry}
 */
proto.CustomerSetups.SetupCountry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CustomerSetups.SetupCountry;
  return proto.CustomerSetups.SetupCountry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CustomerSetups.SetupCountry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CustomerSetups.SetupCountry}
 */
proto.CustomerSetups.SetupCountry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountrycode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountrydialcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CustomerSetups.SetupCountry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CustomerSetups.SetupCountry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CustomerSetups.SetupCountry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.SetupCountry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountrycode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountryname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountrydialcode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string countryCode = 1;
 * @return {string}
 */
proto.CustomerSetups.SetupCountry.prototype.getCountrycode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CustomerSetups.SetupCountry} returns this
 */
proto.CustomerSetups.SetupCountry.prototype.setCountrycode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string countryName = 2;
 * @return {string}
 */
proto.CustomerSetups.SetupCountry.prototype.getCountryname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CustomerSetups.SetupCountry} returns this
 */
proto.CustomerSetups.SetupCountry.prototype.setCountryname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string countryDialCode = 3;
 * @return {string}
 */
proto.CustomerSetups.SetupCountry.prototype.getCountrydialcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.CustomerSetups.SetupCountry} returns this
 */
proto.CustomerSetups.SetupCountry.prototype.setCountrydialcode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.CustomerSetups.ListOfSetupCountryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CustomerSetups.ListOfSetupCountryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CustomerSetups.ListOfSetupCountryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CustomerSetups.ListOfSetupCountryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.ListOfSetupCountryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    countrylistList: jspb.Message.toObjectList(msg.getCountrylistList(),
    proto.CustomerSetups.SetupCountry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CustomerSetups.ListOfSetupCountryResponse}
 */
proto.CustomerSetups.ListOfSetupCountryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CustomerSetups.ListOfSetupCountryResponse;
  return proto.CustomerSetups.ListOfSetupCountryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CustomerSetups.ListOfSetupCountryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CustomerSetups.ListOfSetupCountryResponse}
 */
proto.CustomerSetups.ListOfSetupCountryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.CustomerSetups.SetupCountry;
      reader.readMessage(value,proto.CustomerSetups.SetupCountry.deserializeBinaryFromReader);
      msg.addCountrylist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CustomerSetups.ListOfSetupCountryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CustomerSetups.ListOfSetupCountryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CustomerSetups.ListOfSetupCountryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CustomerSetups.ListOfSetupCountryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountrylistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.CustomerSetups.SetupCountry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SetupCountry countryList = 1;
 * @return {!Array<!proto.CustomerSetups.SetupCountry>}
 */
proto.CustomerSetups.ListOfSetupCountryResponse.prototype.getCountrylistList = function() {
  return /** @type{!Array<!proto.CustomerSetups.SetupCountry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.CustomerSetups.SetupCountry, 1));
};


/**
 * @param {!Array<!proto.CustomerSetups.SetupCountry>} value
 * @return {!proto.CustomerSetups.ListOfSetupCountryResponse} returns this
*/
proto.CustomerSetups.ListOfSetupCountryResponse.prototype.setCountrylistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.CustomerSetups.SetupCountry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.CustomerSetups.SetupCountry}
 */
proto.CustomerSetups.ListOfSetupCountryResponse.prototype.addCountrylist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.CustomerSetups.SetupCountry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CustomerSetups.ListOfSetupCountryResponse} returns this
 */
proto.CustomerSetups.ListOfSetupCountryResponse.prototype.clearCountrylistList = function() {
  return this.setCountrylistList([]);
};


goog.object.extend(exports, proto.CustomerSetups);
