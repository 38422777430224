/**
 * @fileoverview gRPC-Web generated client stub for CustomerSetups
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.1
// source: CustomerSetups.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.CustomerSetups = require('./CustomerSetups_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.CustomerSetups.CustomerSetupsSvcClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.CustomerSetups.CustomerSetupsSvcPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.CustomerSetups.EmptySetupRequest,
 *   !proto.CustomerSetups.RegionResponse>}
 */
const methodDescriptor_CustomerSetupsSvc_GetRegions = new grpc.web.MethodDescriptor(
  '/CustomerSetups.CustomerSetupsSvc/GetRegions',
  grpc.web.MethodType.UNARY,
  proto.CustomerSetups.EmptySetupRequest,
  proto.CustomerSetups.RegionResponse,
  /**
   * @param {!proto.CustomerSetups.EmptySetupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.CustomerSetups.RegionResponse.deserializeBinary
);


/**
 * @param {!proto.CustomerSetups.EmptySetupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.CustomerSetups.RegionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.CustomerSetups.RegionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.CustomerSetups.CustomerSetupsSvcClient.prototype.getRegions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/CustomerSetups.CustomerSetupsSvc/GetRegions',
      request,
      metadata || {},
      methodDescriptor_CustomerSetupsSvc_GetRegions,
      callback);
};


/**
 * @param {!proto.CustomerSetups.EmptySetupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.CustomerSetups.RegionResponse>}
 *     Promise that resolves to the response
 */
proto.CustomerSetups.CustomerSetupsSvcPromiseClient.prototype.getRegions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/CustomerSetups.CustomerSetupsSvc/GetRegions',
      request,
      metadata || {},
      methodDescriptor_CustomerSetupsSvc_GetRegions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.CustomerSetups.EmptySetupRequest,
 *   !proto.CustomerSetups.ConstituenciesResponse>}
 */
const methodDescriptor_CustomerSetupsSvc_GetConstituencies = new grpc.web.MethodDescriptor(
  '/CustomerSetups.CustomerSetupsSvc/GetConstituencies',
  grpc.web.MethodType.UNARY,
  proto.CustomerSetups.EmptySetupRequest,
  proto.CustomerSetups.ConstituenciesResponse,
  /**
   * @param {!proto.CustomerSetups.EmptySetupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.CustomerSetups.ConstituenciesResponse.deserializeBinary
);


/**
 * @param {!proto.CustomerSetups.EmptySetupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.CustomerSetups.ConstituenciesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.CustomerSetups.ConstituenciesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.CustomerSetups.CustomerSetupsSvcClient.prototype.getConstituencies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/CustomerSetups.CustomerSetupsSvc/GetConstituencies',
      request,
      metadata || {},
      methodDescriptor_CustomerSetupsSvc_GetConstituencies,
      callback);
};


/**
 * @param {!proto.CustomerSetups.EmptySetupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.CustomerSetups.ConstituenciesResponse>}
 *     Promise that resolves to the response
 */
proto.CustomerSetups.CustomerSetupsSvcPromiseClient.prototype.getConstituencies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/CustomerSetups.CustomerSetupsSvc/GetConstituencies',
      request,
      metadata || {},
      methodDescriptor_CustomerSetupsSvc_GetConstituencies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.CustomerSetups.EmptySetupRequest,
 *   !proto.CustomerSetups.ListOfAgeRanges>}
 */
const methodDescriptor_CustomerSetupsSvc_GetListOfAgeRange = new grpc.web.MethodDescriptor(
  '/CustomerSetups.CustomerSetupsSvc/GetListOfAgeRange',
  grpc.web.MethodType.UNARY,
  proto.CustomerSetups.EmptySetupRequest,
  proto.CustomerSetups.ListOfAgeRanges,
  /**
   * @param {!proto.CustomerSetups.EmptySetupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.CustomerSetups.ListOfAgeRanges.deserializeBinary
);


/**
 * @param {!proto.CustomerSetups.EmptySetupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.CustomerSetups.ListOfAgeRanges)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.CustomerSetups.ListOfAgeRanges>|undefined}
 *     The XHR Node Readable Stream
 */
proto.CustomerSetups.CustomerSetupsSvcClient.prototype.getListOfAgeRange =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/CustomerSetups.CustomerSetupsSvc/GetListOfAgeRange',
      request,
      metadata || {},
      methodDescriptor_CustomerSetupsSvc_GetListOfAgeRange,
      callback);
};


/**
 * @param {!proto.CustomerSetups.EmptySetupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.CustomerSetups.ListOfAgeRanges>}
 *     Promise that resolves to the response
 */
proto.CustomerSetups.CustomerSetupsSvcPromiseClient.prototype.getListOfAgeRange =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/CustomerSetups.CustomerSetupsSvc/GetListOfAgeRange',
      request,
      metadata || {},
      methodDescriptor_CustomerSetupsSvc_GetListOfAgeRange);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.CustomerSetups.EmptySetupRequest,
 *   !proto.CustomerSetups.ListOfPaymentMethods>}
 */
const methodDescriptor_CustomerSetupsSvc_GetPaymentMethods = new grpc.web.MethodDescriptor(
  '/CustomerSetups.CustomerSetupsSvc/GetPaymentMethods',
  grpc.web.MethodType.UNARY,
  proto.CustomerSetups.EmptySetupRequest,
  proto.CustomerSetups.ListOfPaymentMethods,
  /**
   * @param {!proto.CustomerSetups.EmptySetupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.CustomerSetups.ListOfPaymentMethods.deserializeBinary
);


/**
 * @param {!proto.CustomerSetups.EmptySetupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.CustomerSetups.ListOfPaymentMethods)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.CustomerSetups.ListOfPaymentMethods>|undefined}
 *     The XHR Node Readable Stream
 */
proto.CustomerSetups.CustomerSetupsSvcClient.prototype.getPaymentMethods =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/CustomerSetups.CustomerSetupsSvc/GetPaymentMethods',
      request,
      metadata || {},
      methodDescriptor_CustomerSetupsSvc_GetPaymentMethods,
      callback);
};


/**
 * @param {!proto.CustomerSetups.EmptySetupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.CustomerSetups.ListOfPaymentMethods>}
 *     Promise that resolves to the response
 */
proto.CustomerSetups.CustomerSetupsSvcPromiseClient.prototype.getPaymentMethods =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/CustomerSetups.CustomerSetupsSvc/GetPaymentMethods',
      request,
      metadata || {},
      methodDescriptor_CustomerSetupsSvc_GetPaymentMethods);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.CustomerSetups.EmptySetupRequest,
 *   !proto.CustomerSetups.ListOfSetupCountryResponse>}
 */
const methodDescriptor_CustomerSetupsSvc_GetSetupCountry = new grpc.web.MethodDescriptor(
  '/CustomerSetups.CustomerSetupsSvc/GetSetupCountry',
  grpc.web.MethodType.UNARY,
  proto.CustomerSetups.EmptySetupRequest,
  proto.CustomerSetups.ListOfSetupCountryResponse,
  /**
   * @param {!proto.CustomerSetups.EmptySetupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.CustomerSetups.ListOfSetupCountryResponse.deserializeBinary
);


/**
 * @param {!proto.CustomerSetups.EmptySetupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.CustomerSetups.ListOfSetupCountryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.CustomerSetups.ListOfSetupCountryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.CustomerSetups.CustomerSetupsSvcClient.prototype.getSetupCountry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/CustomerSetups.CustomerSetupsSvc/GetSetupCountry',
      request,
      metadata || {},
      methodDescriptor_CustomerSetupsSvc_GetSetupCountry,
      callback);
};


/**
 * @param {!proto.CustomerSetups.EmptySetupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.CustomerSetups.ListOfSetupCountryResponse>}
 *     Promise that resolves to the response
 */
proto.CustomerSetups.CustomerSetupsSvcPromiseClient.prototype.getSetupCountry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/CustomerSetups.CustomerSetupsSvc/GetSetupCountry',
      request,
      metadata || {},
      methodDescriptor_CustomerSetupsSvc_GetSetupCountry);
};


module.exports = proto.CustomerSetups;

