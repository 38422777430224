// source: Members.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.Members.AccountCreationStatus', null, global);
goog.exportSymbol('proto.Members.AgentMemberResponse', null, global);
goog.exportSymbol('proto.Members.CreateMemberResponse', null, global);
goog.exportSymbol('proto.Members.GetMembersRequest', null, global);
goog.exportSymbol('proto.Members.JMOrGGCValues', null, global);
goog.exportSymbol('proto.Members.JMRequest', null, global);
goog.exportSymbol('proto.Members.JMResponse', null, global);
goog.exportSymbol('proto.Members.Member', null, global);
goog.exportSymbol('proto.Members.MemberEmptyRequest', null, global);
goog.exportSymbol('proto.Members.MemberExistRequest', null, global);
goog.exportSymbol('proto.Members.MemberExistResponse', null, global);
goog.exportSymbol('proto.Members.MemberListResponse', null, global);
goog.exportSymbol('proto.Members.MemberLoginRequest', null, global);
goog.exportSymbol('proto.Members.MemberLoginResponse', null, global);
goog.exportSymbol('proto.Members.MemberRequest', null, global);
goog.exportSymbol('proto.Members.MemberResponse', null, global);
goog.exportSymbol('proto.Members.MemberUssdRequest', null, global);
goog.exportSymbol('proto.Members.MobileOrUssd', null, global);
goog.exportSymbol('proto.Members.ResetPinRequest', null, global);
goog.exportSymbol('proto.Members.ResetPinResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Members.MemberEmptyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Members.MemberEmptyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Members.MemberEmptyRequest.displayName = 'proto.Members.MemberEmptyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Members.AgentMemberResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Members.AgentMemberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Members.AgentMemberResponse.displayName = 'proto.Members.AgentMemberResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Members.GetMembersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Members.GetMembersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Members.GetMembersRequest.displayName = 'proto.Members.GetMembersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Members.MemberExistRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Members.MemberExistRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Members.MemberExistRequest.displayName = 'proto.Members.MemberExistRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Members.ResetPinRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Members.ResetPinRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Members.ResetPinRequest.displayName = 'proto.Members.ResetPinRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Members.ResetPinResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Members.ResetPinResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Members.ResetPinResponse.displayName = 'proto.Members.ResetPinResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Members.MemberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Members.MemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Members.MemberRequest.displayName = 'proto.Members.MemberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Members.MemberUssdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Members.MemberUssdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Members.MemberUssdRequest.displayName = 'proto.Members.MemberUssdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Members.MemberResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Members.MemberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Members.MemberResponse.displayName = 'proto.Members.MemberResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Members.MemberExistResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Members.MemberExistResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Members.MemberExistResponse.displayName = 'proto.Members.MemberExistResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Members.CreateMemberResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Members.CreateMemberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Members.CreateMemberResponse.displayName = 'proto.Members.CreateMemberResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Members.Member = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Members.Member, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Members.Member.displayName = 'proto.Members.Member';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Members.MemberListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Members.MemberListResponse.repeatedFields_, null);
};
goog.inherits(proto.Members.MemberListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Members.MemberListResponse.displayName = 'proto.Members.MemberListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Members.MemberLoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Members.MemberLoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Members.MemberLoginRequest.displayName = 'proto.Members.MemberLoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Members.MemberLoginResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Members.MemberLoginResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Members.MemberLoginResponse.displayName = 'proto.Members.MemberLoginResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Members.JMRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Members.JMRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Members.JMRequest.displayName = 'proto.Members.JMRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Members.JMResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Members.JMResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Members.JMResponse.displayName = 'proto.Members.JMResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Members.MemberEmptyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Members.MemberEmptyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Members.MemberEmptyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberEmptyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Members.MemberEmptyRequest}
 */
proto.Members.MemberEmptyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Members.MemberEmptyRequest;
  return proto.Members.MemberEmptyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Members.MemberEmptyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Members.MemberEmptyRequest}
 */
proto.Members.MemberEmptyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Members.MemberEmptyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Members.MemberEmptyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Members.MemberEmptyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberEmptyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Members.AgentMemberResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Members.AgentMemberResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Members.AgentMemberResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.AgentMemberResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Members.AgentMemberResponse}
 */
proto.Members.AgentMemberResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Members.AgentMemberResponse;
  return proto.Members.AgentMemberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Members.AgentMemberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Members.AgentMemberResponse}
 */
proto.Members.AgentMemberResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Members.AgentMemberResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Members.AgentMemberResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Members.AgentMemberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.AgentMemberResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Members.GetMembersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Members.GetMembersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Members.GetMembersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.GetMembersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    membertypejmorggc: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Members.GetMembersRequest}
 */
proto.Members.GetMembersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Members.GetMembersRequest;
  return proto.Members.GetMembersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Members.GetMembersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Members.GetMembersRequest}
 */
proto.Members.GetMembersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMembertypejmorggc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Members.GetMembersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Members.GetMembersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Members.GetMembersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.GetMembersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMembertypejmorggc();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string MemberTypeJMORGGC = 1;
 * @return {string}
 */
proto.Members.GetMembersRequest.prototype.getMembertypejmorggc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.GetMembersRequest} returns this
 */
proto.Members.GetMembersRequest.prototype.setMembertypejmorggc = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Members.MemberExistRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Members.MemberExistRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Members.MemberExistRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberExistRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobilenumberoremailaddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    membertype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Members.MemberExistRequest}
 */
proto.Members.MemberExistRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Members.MemberExistRequest;
  return proto.Members.MemberExistRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Members.MemberExistRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Members.MemberExistRequest}
 */
proto.Members.MemberExistRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumberoremailaddress(value);
      break;
    case 2:
      var value = /** @type {!proto.Members.JMOrGGCValues} */ (reader.readEnum());
      msg.setMembertype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Members.MemberExistRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Members.MemberExistRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Members.MemberExistRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberExistRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobilenumberoremailaddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMembertype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string mobileNumberOrEmailAddress = 1;
 * @return {string}
 */
proto.Members.MemberExistRequest.prototype.getMobilenumberoremailaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberExistRequest} returns this
 */
proto.Members.MemberExistRequest.prototype.setMobilenumberoremailaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional JMOrGGCValues memberType = 2;
 * @return {!proto.Members.JMOrGGCValues}
 */
proto.Members.MemberExistRequest.prototype.getMembertype = function() {
  return /** @type {!proto.Members.JMOrGGCValues} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.Members.JMOrGGCValues} value
 * @return {!proto.Members.MemberExistRequest} returns this
 */
proto.Members.MemberExistRequest.prototype.setMembertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Members.ResetPinRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Members.ResetPinRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Members.ResetPinRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.ResetPinRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pin: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mobilenumberoremailaddress: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Members.ResetPinRequest}
 */
proto.Members.ResetPinRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Members.ResetPinRequest;
  return proto.Members.ResetPinRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Members.ResetPinRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Members.ResetPinRequest}
 */
proto.Members.ResetPinRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumberoremailaddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Members.ResetPinRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Members.ResetPinRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Members.ResetPinRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.ResetPinRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMobilenumberoremailaddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string PIN = 1;
 * @return {string}
 */
proto.Members.ResetPinRequest.prototype.getPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.ResetPinRequest} returns this
 */
proto.Members.ResetPinRequest.prototype.setPin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mobileNumberOrEmailAddress = 2;
 * @return {string}
 */
proto.Members.ResetPinRequest.prototype.getMobilenumberoremailaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.ResetPinRequest} returns this
 */
proto.Members.ResetPinRequest.prototype.setMobilenumberoremailaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Members.ResetPinResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Members.ResetPinResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Members.ResetPinResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.ResetPinResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Members.ResetPinResponse}
 */
proto.Members.ResetPinResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Members.ResetPinResponse;
  return proto.Members.ResetPinResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Members.ResetPinResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Members.ResetPinResponse}
 */
proto.Members.ResetPinResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Members.ResetPinResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Members.ResetPinResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Members.ResetPinResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.ResetPinResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.Members.ResetPinResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Members.ResetPinResponse} returns this
 */
proto.Members.ResetPinResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.Members.ResetPinResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.ResetPinResponse} returns this
 */
proto.Members.ResetPinResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Members.MemberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Members.MemberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Members.MemberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fullname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    emailaddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 4, ""),
    age: jspb.Message.getFieldWithDefault(msg, 5, 0),
    countryofresidence: jspb.Message.getFieldWithDefault(msg, 6, ""),
    regionid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    constituencyid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    industry: jspb.Message.getFieldWithDefault(msg, 9, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 10, ""),
    ndcmemberidno: jspb.Message.getFieldWithDefault(msg, 11, ""),
    agentid: jspb.Message.getFieldWithDefault(msg, 12, ""),
    pin: jspb.Message.getFieldWithDefault(msg, 13, ""),
    mobielwebussd: jspb.Message.getFieldWithDefault(msg, 14, ""),
    agerageid: jspb.Message.getFieldWithDefault(msg, 15, ""),
    displaynameoncard: jspb.Message.getFieldWithDefault(msg, 16, ""),
    cardpickuplocation: jspb.Message.getFieldWithDefault(msg, 17, ""),
    cardtypeid: jspb.Message.getFieldWithDefault(msg, 18, ""),
    accountcreationstatus: jspb.Message.getFieldWithDefault(msg, 19, 0),
    countrycode: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Members.MemberRequest}
 */
proto.Members.MemberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Members.MemberRequest;
  return proto.Members.MemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Members.MemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Members.MemberRequest}
 */
proto.Members.MemberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailaddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGender(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAge(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryofresidence(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setConstituencyid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndustry(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setNdcmemberidno(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPin(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobielwebussd(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgerageid(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplaynameoncard(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardpickuplocation(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardtypeid(value);
      break;
    case 19:
      var value = /** @type {!proto.Members.AccountCreationStatus} */ (reader.readEnum());
      msg.setAccountcreationstatus(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountrycode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Members.MemberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Members.MemberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Members.MemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFullname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmailaddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGender();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAge();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCountryofresidence();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRegionid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getConstituencyid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIndustry();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getNdcmemberidno();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPin();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getMobielwebussd();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAgerageid();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDisplaynameoncard();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCardpickuplocation();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCardtypeid();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getAccountcreationstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getCountrycode();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string fullName = 1;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getFullname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setFullname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mobileNumber = 2;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string emailAddress = 3;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getEmailaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setEmailaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string gender = 4;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getGender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setGender = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 age = 5;
 * @return {number}
 */
proto.Members.MemberRequest.prototype.getAge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setAge = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string countryOfResidence = 6;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getCountryofresidence = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setCountryofresidence = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string regionId = 7;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getRegionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setRegionid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string constituencyId = 8;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getConstituencyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setConstituencyid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string industry = 9;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getIndustry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setIndustry = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string occupation = 10;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string NDCMemberIdNo = 11;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getNdcmemberidno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setNdcmemberidno = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string agentId = 12;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string PIN = 13;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setPin = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string mobielWebUssd = 14;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getMobielwebussd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setMobielwebussd = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string ageRageId = 15;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getAgerageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setAgerageid = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string displayNameOnCard = 16;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getDisplaynameoncard = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setDisplaynameoncard = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string cardPickUpLocation = 17;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getCardpickuplocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setCardpickuplocation = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string cardTypeId = 18;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getCardtypeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setCardtypeid = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional AccountCreationStatus accountCreationStatus = 19;
 * @return {!proto.Members.AccountCreationStatus}
 */
proto.Members.MemberRequest.prototype.getAccountcreationstatus = function() {
  return /** @type {!proto.Members.AccountCreationStatus} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.Members.AccountCreationStatus} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setAccountcreationstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional string countryCode = 20;
 * @return {string}
 */
proto.Members.MemberRequest.prototype.getCountrycode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberRequest} returns this
 */
proto.Members.MemberRequest.prototype.setCountrycode = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Members.MemberUssdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Members.MemberUssdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Members.MemberUssdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberUssdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    networkid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    membertype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cardtypeid: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Members.MemberUssdRequest}
 */
proto.Members.MemberUssdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Members.MemberUssdRequest;
  return proto.Members.MemberUssdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Members.MemberUssdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Members.MemberUssdRequest}
 */
proto.Members.MemberUssdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetworkid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMembertype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardtypeid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Members.MemberUssdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Members.MemberUssdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Members.MemberUssdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberUssdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNetworkid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMembertype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCardtypeid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string mobileNumber = 2;
 * @return {string}
 */
proto.Members.MemberUssdRequest.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberUssdRequest} returns this
 */
proto.Members.MemberUssdRequest.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string networkId = 3;
 * @return {string}
 */
proto.Members.MemberUssdRequest.prototype.getNetworkid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberUssdRequest} returns this
 */
proto.Members.MemberUssdRequest.prototype.setNetworkid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string memberType = 4;
 * @return {string}
 */
proto.Members.MemberUssdRequest.prototype.getMembertype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberUssdRequest} returns this
 */
proto.Members.MemberUssdRequest.prototype.setMembertype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string cardTypeId = 5;
 * @return {string}
 */
proto.Members.MemberUssdRequest.prototype.getCardtypeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberUssdRequest} returns this
 */
proto.Members.MemberUssdRequest.prototype.setCardtypeid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Members.MemberResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Members.MemberResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Members.MemberResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Members.MemberResponse}
 */
proto.Members.MemberResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Members.MemberResponse;
  return proto.Members.MemberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Members.MemberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Members.MemberResponse}
 */
proto.Members.MemberResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Members.MemberResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Members.MemberResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Members.MemberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.Members.MemberResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Members.MemberResponse} returns this
 */
proto.Members.MemberResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.Members.MemberResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberResponse} returns this
 */
proto.Members.MemberResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Members.MemberExistResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Members.MemberExistResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Members.MemberExistResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberExistResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountstatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cardno: jspb.Message.getFieldWithDefault(msg, 4, ""),
    memberid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    membername: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Members.MemberExistResponse}
 */
proto.Members.MemberExistResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Members.MemberExistResponse;
  return proto.Members.MemberExistResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Members.MemberExistResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Members.MemberExistResponse}
 */
proto.Members.MemberExistResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {!proto.Members.AccountCreationStatus} */ (reader.readEnum());
      msg.setAccountstatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardno(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMembername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Members.MemberExistResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Members.MemberExistResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Members.MemberExistResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberExistResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCardno();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMembername();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.Members.MemberExistResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Members.MemberExistResponse} returns this
 */
proto.Members.MemberExistResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.Members.MemberExistResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberExistResponse} returns this
 */
proto.Members.MemberExistResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AccountCreationStatus accountStatus = 3;
 * @return {!proto.Members.AccountCreationStatus}
 */
proto.Members.MemberExistResponse.prototype.getAccountstatus = function() {
  return /** @type {!proto.Members.AccountCreationStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.Members.AccountCreationStatus} value
 * @return {!proto.Members.MemberExistResponse} returns this
 */
proto.Members.MemberExistResponse.prototype.setAccountstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string cardNo = 4;
 * @return {string}
 */
proto.Members.MemberExistResponse.prototype.getCardno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberExistResponse} returns this
 */
proto.Members.MemberExistResponse.prototype.setCardno = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string memberId = 5;
 * @return {string}
 */
proto.Members.MemberExistResponse.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberExistResponse} returns this
 */
proto.Members.MemberExistResponse.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string MemberName = 6;
 * @return {string}
 */
proto.Members.MemberExistResponse.prototype.getMembername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberExistResponse} returns this
 */
proto.Members.MemberExistResponse.prototype.setMembername = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Members.CreateMemberResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Members.CreateMemberResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Members.CreateMemberResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.CreateMemberResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    memberid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cardnumber: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Members.CreateMemberResponse}
 */
proto.Members.CreateMemberResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Members.CreateMemberResponse;
  return proto.Members.CreateMemberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Members.CreateMemberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Members.CreateMemberResponse}
 */
proto.Members.CreateMemberResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Members.CreateMemberResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Members.CreateMemberResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Members.CreateMemberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.CreateMemberResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCardnumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.Members.CreateMemberResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Members.CreateMemberResponse} returns this
 */
proto.Members.CreateMemberResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.Members.CreateMemberResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.CreateMemberResponse} returns this
 */
proto.Members.CreateMemberResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string memberId = 3;
 * @return {string}
 */
proto.Members.CreateMemberResponse.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.CreateMemberResponse} returns this
 */
proto.Members.CreateMemberResponse.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cardNumber = 4;
 * @return {string}
 */
proto.Members.CreateMemberResponse.prototype.getCardnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.CreateMemberResponse} returns this
 */
proto.Members.CreateMemberResponse.prototype.setCardnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Members.Member.prototype.toObject = function(opt_includeInstance) {
  return proto.Members.Member.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Members.Member} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.Member.toObject = function(includeInstance, msg) {
  var f, obj = {
    fullname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    emailaddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 4, ""),
    age: jspb.Message.getFieldWithDefault(msg, 5, 0),
    countryofresedence: jspb.Message.getFieldWithDefault(msg, 6, ""),
    regionid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    constituencyid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    industry: jspb.Message.getFieldWithDefault(msg, 9, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 10, ""),
    ndcmemberidno: jspb.Message.getFieldWithDefault(msg, 11, ""),
    agentid: jspb.Message.getFieldWithDefault(msg, 12, ""),
    mobielwebussd: jspb.Message.getFieldWithDefault(msg, 14, ""),
    agerangeid: jspb.Message.getFieldWithDefault(msg, 15, ""),
    displaynameoncard: jspb.Message.getFieldWithDefault(msg, 16, ""),
    cardpickuplocation: jspb.Message.getFieldWithDefault(msg, 17, ""),
    cardstatus: jspb.Message.getFieldWithDefault(msg, 18, ""),
    cardnumber: jspb.Message.getFieldWithDefault(msg, 19, ""),
    id: jspb.Message.getFieldWithDefault(msg, 20, 0),
    transamount: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Members.Member}
 */
proto.Members.Member.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Members.Member;
  return proto.Members.Member.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Members.Member} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Members.Member}
 */
proto.Members.Member.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailaddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGender(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAge(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryofresedence(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setConstituencyid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndustry(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setNdcmemberidno(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobielwebussd(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgerangeid(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplaynameoncard(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardpickuplocation(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardstatus(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardnumber(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTransamount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Members.Member.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Members.Member.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Members.Member} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.Member.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFullname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmailaddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGender();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAge();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCountryofresedence();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRegionid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getConstituencyid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIndustry();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getNdcmemberidno();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMobielwebussd();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAgerangeid();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDisplaynameoncard();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCardpickuplocation();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCardstatus();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getCardnumber();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getTransamount();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
};


/**
 * optional string fullName = 1;
 * @return {string}
 */
proto.Members.Member.prototype.getFullname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setFullname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mobileNumber = 2;
 * @return {string}
 */
proto.Members.Member.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string emailAddress = 3;
 * @return {string}
 */
proto.Members.Member.prototype.getEmailaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setEmailaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string gender = 4;
 * @return {string}
 */
proto.Members.Member.prototype.getGender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setGender = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 age = 5;
 * @return {number}
 */
proto.Members.Member.prototype.getAge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setAge = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string countryOfResedence = 6;
 * @return {string}
 */
proto.Members.Member.prototype.getCountryofresedence = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setCountryofresedence = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string regionId = 7;
 * @return {string}
 */
proto.Members.Member.prototype.getRegionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setRegionid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string constituencyId = 8;
 * @return {string}
 */
proto.Members.Member.prototype.getConstituencyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setConstituencyid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string industry = 9;
 * @return {string}
 */
proto.Members.Member.prototype.getIndustry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setIndustry = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string occupation = 10;
 * @return {string}
 */
proto.Members.Member.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string NDCMemberIdNo = 11;
 * @return {string}
 */
proto.Members.Member.prototype.getNdcmemberidno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setNdcmemberidno = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string agentId = 12;
 * @return {string}
 */
proto.Members.Member.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string mobielWebUssd = 14;
 * @return {string}
 */
proto.Members.Member.prototype.getMobielwebussd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setMobielwebussd = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string ageRangeId = 15;
 * @return {string}
 */
proto.Members.Member.prototype.getAgerangeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setAgerangeid = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string displayNameOnCard = 16;
 * @return {string}
 */
proto.Members.Member.prototype.getDisplaynameoncard = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setDisplaynameoncard = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string cardPickUpLocation = 17;
 * @return {string}
 */
proto.Members.Member.prototype.getCardpickuplocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setCardpickuplocation = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string cardStatus = 18;
 * @return {string}
 */
proto.Members.Member.prototype.getCardstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setCardstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string cardNumber = 19;
 * @return {string}
 */
proto.Members.Member.prototype.getCardnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setCardnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int32 id = 20;
 * @return {number}
 */
proto.Members.Member.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional double transAmount = 21;
 * @return {number}
 */
proto.Members.Member.prototype.getTransamount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Members.Member} returns this
 */
proto.Members.Member.prototype.setTransamount = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Members.MemberListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Members.MemberListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Members.MemberListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Members.MemberListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    memberlistList: jspb.Message.toObjectList(msg.getMemberlistList(),
    proto.Members.Member.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Members.MemberListResponse}
 */
proto.Members.MemberListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Members.MemberListResponse;
  return proto.Members.MemberListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Members.MemberListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Members.MemberListResponse}
 */
proto.Members.MemberListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Members.Member;
      reader.readMessage(value,proto.Members.Member.deserializeBinaryFromReader);
      msg.addMemberlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Members.MemberListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Members.MemberListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Members.MemberListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMemberlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Members.Member.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Member memberList = 1;
 * @return {!Array<!proto.Members.Member>}
 */
proto.Members.MemberListResponse.prototype.getMemberlistList = function() {
  return /** @type{!Array<!proto.Members.Member>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Members.Member, 1));
};


/**
 * @param {!Array<!proto.Members.Member>} value
 * @return {!proto.Members.MemberListResponse} returns this
*/
proto.Members.MemberListResponse.prototype.setMemberlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Members.Member=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Members.Member}
 */
proto.Members.MemberListResponse.prototype.addMemberlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Members.Member, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Members.MemberListResponse} returns this
 */
proto.Members.MemberListResponse.prototype.clearMemberlistList = function() {
  return this.setMemberlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Members.MemberLoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Members.MemberLoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Members.MemberLoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberLoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobilenumberoremailaddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accounttype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    platformtype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Members.MemberLoginRequest}
 */
proto.Members.MemberLoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Members.MemberLoginRequest;
  return proto.Members.MemberLoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Members.MemberLoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Members.MemberLoginRequest}
 */
proto.Members.MemberLoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumberoremailaddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPin(value);
      break;
    case 3:
      var value = /** @type {!proto.Members.JMOrGGCValues} */ (reader.readEnum());
      msg.setAccounttype(value);
      break;
    case 4:
      var value = /** @type {!proto.Members.MobileOrUssd} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Members.MemberLoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Members.MemberLoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Members.MemberLoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberLoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobilenumberoremailaddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccounttype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPlatformtype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string mobileNumberOrEmailAddress = 1;
 * @return {string}
 */
proto.Members.MemberLoginRequest.prototype.getMobilenumberoremailaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberLoginRequest} returns this
 */
proto.Members.MemberLoginRequest.prototype.setMobilenumberoremailaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string PIN = 2;
 * @return {string}
 */
proto.Members.MemberLoginRequest.prototype.getPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberLoginRequest} returns this
 */
proto.Members.MemberLoginRequest.prototype.setPin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional JMOrGGCValues accountType = 3;
 * @return {!proto.Members.JMOrGGCValues}
 */
proto.Members.MemberLoginRequest.prototype.getAccounttype = function() {
  return /** @type {!proto.Members.JMOrGGCValues} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.Members.JMOrGGCValues} value
 * @return {!proto.Members.MemberLoginRequest} returns this
 */
proto.Members.MemberLoginRequest.prototype.setAccounttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional MobileOrUssd platformType = 4;
 * @return {!proto.Members.MobileOrUssd}
 */
proto.Members.MemberLoginRequest.prototype.getPlatformtype = function() {
  return /** @type {!proto.Members.MobileOrUssd} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.Members.MobileOrUssd} value
 * @return {!proto.Members.MemberLoginRequest} returns this
 */
proto.Members.MemberLoginRequest.prototype.setPlatformtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Members.MemberLoginResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Members.MemberLoginResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Members.MemberLoginResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberLoginResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cardnumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    memberid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cardtypeid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cardtypename: jspb.Message.getFieldWithDefault(msg, 6, ""),
    fullname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    registrationdate: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Members.MemberLoginResponse}
 */
proto.Members.MemberLoginResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Members.MemberLoginResponse;
  return proto.Members.MemberLoginResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Members.MemberLoginResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Members.MemberLoginResponse}
 */
proto.Members.MemberLoginResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardnumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardtypeid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardtypename(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullname(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Members.MemberLoginResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Members.MemberLoginResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Members.MemberLoginResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.MemberLoginResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCardnumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCardtypeid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCardtypename();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFullname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRegistrationdate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.Members.MemberLoginResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Members.MemberLoginResponse} returns this
 */
proto.Members.MemberLoginResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.Members.MemberLoginResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberLoginResponse} returns this
 */
proto.Members.MemberLoginResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string cardNumber = 3;
 * @return {string}
 */
proto.Members.MemberLoginResponse.prototype.getCardnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberLoginResponse} returns this
 */
proto.Members.MemberLoginResponse.prototype.setCardnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string memberId = 4;
 * @return {string}
 */
proto.Members.MemberLoginResponse.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberLoginResponse} returns this
 */
proto.Members.MemberLoginResponse.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string cardTypeId = 5;
 * @return {string}
 */
proto.Members.MemberLoginResponse.prototype.getCardtypeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberLoginResponse} returns this
 */
proto.Members.MemberLoginResponse.prototype.setCardtypeid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string cardTypeName = 6;
 * @return {string}
 */
proto.Members.MemberLoginResponse.prototype.getCardtypename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberLoginResponse} returns this
 */
proto.Members.MemberLoginResponse.prototype.setCardtypename = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string fullName = 7;
 * @return {string}
 */
proto.Members.MemberLoginResponse.prototype.getFullname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberLoginResponse} returns this
 */
proto.Members.MemberLoginResponse.prototype.setFullname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string registrationDate = 8;
 * @return {string}
 */
proto.Members.MemberLoginResponse.prototype.getRegistrationdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.MemberLoginResponse} returns this
 */
proto.Members.MemberLoginResponse.prototype.setRegistrationdate = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Members.JMRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Members.JMRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Members.JMRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.JMRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobilenumberoremailaddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    agentid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Members.JMRequest}
 */
proto.Members.JMRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Members.JMRequest;
  return proto.Members.JMRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Members.JMRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Members.JMRequest}
 */
proto.Members.JMRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumberoremailaddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Members.JMRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Members.JMRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Members.JMRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.JMRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobilenumberoremailaddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string mobileNumberOrEmailAddress = 1;
 * @return {string}
 */
proto.Members.JMRequest.prototype.getMobilenumberoremailaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.JMRequest} returns this
 */
proto.Members.JMRequest.prototype.setMobilenumberoremailaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string PIN = 2;
 * @return {string}
 */
proto.Members.JMRequest.prototype.getPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.JMRequest} returns this
 */
proto.Members.JMRequest.prototype.setPin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string agentId = 3;
 * @return {string}
 */
proto.Members.JMRequest.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.JMRequest} returns this
 */
proto.Members.JMRequest.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Members.JMResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Members.JMResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Members.JMResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.JMResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    memberid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Members.JMResponse}
 */
proto.Members.JMResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Members.JMResponse;
  return proto.Members.JMResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Members.JMResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Members.JMResponse}
 */
proto.Members.JMResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Members.JMResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Members.JMResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Members.JMResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Members.JMResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.Members.JMResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Members.JMResponse} returns this
 */
proto.Members.JMResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.Members.JMResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.JMResponse} returns this
 */
proto.Members.JMResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string memberId = 3;
 * @return {string}
 */
proto.Members.JMResponse.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Members.JMResponse} returns this
 */
proto.Members.JMResponse.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.Members.JMOrGGCValues = {
  JM: 0,
  GGC: 1
};

/**
 * @enum {number}
 */
proto.Members.AccountCreationStatus = {
  COMPLETE: 0,
  INCOMPLETE: 1,
  DOES_NOT_EXIST: 2
};

/**
 * @enum {number}
 */
proto.Members.MobileOrUssd = {
  MOBILE: 0,
  USSD: 1
};

goog.object.extend(exports, proto.Members);
