// source: Transaction.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.Transaction.CardTypePerTransactionRequest', null, global);
goog.exportSymbol('proto.Transaction.CardTypePerTransactionResponse', null, global);
goog.exportSymbol('proto.Transaction.ChargesRequest', null, global);
goog.exportSymbol('proto.Transaction.ChargesResponse', null, global);
goog.exportSymbol('proto.Transaction.DonatedAmountRequest', null, global);
goog.exportSymbol('proto.Transaction.DontaedAmountResponse', null, global);
goog.exportSymbol('proto.Transaction.KycRequest', null, global);
goog.exportSymbol('proto.Transaction.KycResponse', null, global);
goog.exportSymbol('proto.Transaction.PostDataRequest', null, global);
goog.exportSymbol('proto.Transaction.PostDataUssdRequest', null, global);
goog.exportSymbol('proto.Transaction.StatusCheckRequest', null, global);
goog.exportSymbol('proto.Transaction.StatusCheckResponse', null, global);
goog.exportSymbol('proto.Transaction.StatusRequest', null, global);
goog.exportSymbol('proto.Transaction.StatusResponse', null, global);
goog.exportSymbol('proto.Transaction.TotalTransactionDoneByAgentRequest', null, global);
goog.exportSymbol('proto.Transaction.TotalTransactionDoneByAgentResponse', null, global);
goog.exportSymbol('proto.Transaction.TransAdminRequest', null, global);
goog.exportSymbol('proto.Transaction.TransResponse', null, global);
goog.exportSymbol('proto.Transaction.TransactionData', null, global);
goog.exportSymbol('proto.Transaction.TransactionHistoryRequest', null, global);
goog.exportSymbol('proto.Transaction.TransactionRequest', null, global);
goog.exportSymbol('proto.Transaction.TransactionResponse', null, global);
goog.exportSymbol('proto.Transaction.TransactionStatus', null, global);
goog.exportSymbol('proto.Transaction.TransactionTypeMonthlyOrUpgrade', null, global);
goog.exportSymbol('proto.Transaction.UssdChargesRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.PostDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.PostDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.PostDataRequest.displayName = 'proto.Transaction.PostDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.StatusCheckRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.StatusCheckRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.StatusCheckRequest.displayName = 'proto.Transaction.StatusCheckRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.DonatedAmountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.DonatedAmountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.DonatedAmountRequest.displayName = 'proto.Transaction.DonatedAmountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.DontaedAmountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.DontaedAmountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.DontaedAmountResponse.displayName = 'proto.Transaction.DontaedAmountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.PostDataUssdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.PostDataUssdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.PostDataUssdRequest.displayName = 'proto.Transaction.PostDataUssdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.UssdChargesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.UssdChargesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.UssdChargesRequest.displayName = 'proto.Transaction.UssdChargesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.TransAdminRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.TransAdminRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.TransAdminRequest.displayName = 'proto.Transaction.TransAdminRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.TransResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.TransResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.TransResponse.displayName = 'proto.Transaction.TransResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.ChargesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.ChargesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.ChargesRequest.displayName = 'proto.Transaction.ChargesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.ChargesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.ChargesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.ChargesResponse.displayName = 'proto.Transaction.ChargesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.KycRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.KycRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.KycRequest.displayName = 'proto.Transaction.KycRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.KycResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.KycResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.KycResponse.displayName = 'proto.Transaction.KycResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.TransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.TransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.TransactionRequest.displayName = 'proto.Transaction.TransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.TransactionHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.TransactionHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.TransactionHistoryRequest.displayName = 'proto.Transaction.TransactionHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.TransactionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.TransactionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.TransactionData.displayName = 'proto.Transaction.TransactionData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.TransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Transaction.TransactionResponse.repeatedFields_, null);
};
goog.inherits(proto.Transaction.TransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.TransactionResponse.displayName = 'proto.Transaction.TransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.StatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.StatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.StatusRequest.displayName = 'proto.Transaction.StatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.StatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.StatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.StatusResponse.displayName = 'proto.Transaction.StatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.CardTypePerTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.CardTypePerTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.CardTypePerTransactionRequest.displayName = 'proto.Transaction.CardTypePerTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.CardTypePerTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.CardTypePerTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.CardTypePerTransactionResponse.displayName = 'proto.Transaction.CardTypePerTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.TotalTransactionDoneByAgentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.TotalTransactionDoneByAgentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.TotalTransactionDoneByAgentRequest.displayName = 'proto.Transaction.TotalTransactionDoneByAgentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.TotalTransactionDoneByAgentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.TotalTransactionDoneByAgentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.TotalTransactionDoneByAgentResponse.displayName = 'proto.Transaction.TotalTransactionDoneByAgentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transaction.StatusCheckResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transaction.StatusCheckResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transaction.StatusCheckResponse.displayName = 'proto.Transaction.StatusCheckResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.PostDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.PostDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.PostDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.PostDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobileno: jspb.Message.getFieldWithDefault(msg, 1, ""),
    memberid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    transtypejmorgcc: jspb.Message.getFieldWithDefault(msg, 4, ""),
    transamount: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    paymentacctormomono: jspb.Message.getFieldWithDefault(msg, 7, ""),
    paymentaccountname: jspb.Message.getFieldWithDefault(msg, 8, ""),
    networkid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    narration: jspb.Message.getFieldWithDefault(msg, 10, ""),
    agentid: jspb.Message.getFieldWithDefault(msg, 11, ""),
    cvv: jspb.Message.getFieldWithDefault(msg, 20, ""),
    expirydatemonth: jspb.Message.getFieldWithDefault(msg, 21, 0),
    expirydateyear: jspb.Message.getFieldWithDefault(msg, 22, 0),
    channel: jspb.Message.getFieldWithDefault(msg, 23, ""),
    transtype: jspb.Message.getFieldWithDefault(msg, 24, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.PostDataRequest}
 */
proto.Transaction.PostDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.PostDataRequest;
  return proto.Transaction.PostDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.PostDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.PostDataRequest}
 */
proto.Transaction.PostDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobileno(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTranstypejmorgcc(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTransamount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentacctormomono(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentaccountname(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetworkid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNarration(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCvv(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpirydatemonth(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpirydateyear(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannel(value);
      break;
    case 24:
      var value = /** @type {!proto.Transaction.TransactionTypeMonthlyOrUpgrade} */ (reader.readEnum());
      msg.setTranstype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.PostDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.PostDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.PostDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.PostDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobileno();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTranstypejmorgcc();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTransamount();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPaymentacctormomono();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPaymentaccountname();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNetworkid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getNarration();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCvv();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getExpirydatemonth();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getExpirydateyear();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getChannel();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getTranstype();
  if (f !== 0.0) {
    writer.writeEnum(
      24,
      f
    );
  }
};


/**
 * optional string mobileNo = 1;
 * @return {string}
 */
proto.Transaction.PostDataRequest.prototype.getMobileno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.PostDataRequest} returns this
 */
proto.Transaction.PostDataRequest.prototype.setMobileno = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string memberId = 3;
 * @return {string}
 */
proto.Transaction.PostDataRequest.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.PostDataRequest} returns this
 */
proto.Transaction.PostDataRequest.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string transTypeJMOrGCC = 4;
 * @return {string}
 */
proto.Transaction.PostDataRequest.prototype.getTranstypejmorgcc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.PostDataRequest} returns this
 */
proto.Transaction.PostDataRequest.prototype.setTranstypejmorgcc = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double transAmount = 5;
 * @return {number}
 */
proto.Transaction.PostDataRequest.prototype.getTransamount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Transaction.PostDataRequest} returns this
 */
proto.Transaction.PostDataRequest.prototype.setTransamount = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string paymentAcctOrMomoNo = 7;
 * @return {string}
 */
proto.Transaction.PostDataRequest.prototype.getPaymentacctormomono = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.PostDataRequest} returns this
 */
proto.Transaction.PostDataRequest.prototype.setPaymentacctormomono = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string paymentAccountName = 8;
 * @return {string}
 */
proto.Transaction.PostDataRequest.prototype.getPaymentaccountname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.PostDataRequest} returns this
 */
proto.Transaction.PostDataRequest.prototype.setPaymentaccountname = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string networkId = 9;
 * @return {string}
 */
proto.Transaction.PostDataRequest.prototype.getNetworkid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.PostDataRequest} returns this
 */
proto.Transaction.PostDataRequest.prototype.setNetworkid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string narration = 10;
 * @return {string}
 */
proto.Transaction.PostDataRequest.prototype.getNarration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.PostDataRequest} returns this
 */
proto.Transaction.PostDataRequest.prototype.setNarration = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string agentId = 11;
 * @return {string}
 */
proto.Transaction.PostDataRequest.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.PostDataRequest} returns this
 */
proto.Transaction.PostDataRequest.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string CVV = 20;
 * @return {string}
 */
proto.Transaction.PostDataRequest.prototype.getCvv = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.PostDataRequest} returns this
 */
proto.Transaction.PostDataRequest.prototype.setCvv = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional int32 expiryDateMonth = 21;
 * @return {number}
 */
proto.Transaction.PostDataRequest.prototype.getExpirydatemonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.Transaction.PostDataRequest} returns this
 */
proto.Transaction.PostDataRequest.prototype.setExpirydatemonth = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int32 expiryDateYear = 22;
 * @return {number}
 */
proto.Transaction.PostDataRequest.prototype.getExpirydateyear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.Transaction.PostDataRequest} returns this
 */
proto.Transaction.PostDataRequest.prototype.setExpirydateyear = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional string channel = 23;
 * @return {string}
 */
proto.Transaction.PostDataRequest.prototype.getChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.PostDataRequest} returns this
 */
proto.Transaction.PostDataRequest.prototype.setChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional TransactionTypeMonthlyOrUpgrade transType = 24;
 * @return {!proto.Transaction.TransactionTypeMonthlyOrUpgrade}
 */
proto.Transaction.PostDataRequest.prototype.getTranstype = function() {
  return /** @type {!proto.Transaction.TransactionTypeMonthlyOrUpgrade} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.Transaction.TransactionTypeMonthlyOrUpgrade} value
 * @return {!proto.Transaction.PostDataRequest} returns this
 */
proto.Transaction.PostDataRequest.prototype.setTranstype = function(value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.StatusCheckRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.StatusCheckRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.StatusCheckRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.StatusCheckRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transtypejmorgcc: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.StatusCheckRequest}
 */
proto.Transaction.StatusCheckRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.StatusCheckRequest;
  return proto.Transaction.StatusCheckRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.StatusCheckRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.StatusCheckRequest}
 */
proto.Transaction.StatusCheckRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTranstypejmorgcc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.StatusCheckRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.StatusCheckRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.StatusCheckRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.StatusCheckRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTranstypejmorgcc();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string transTypeJMOrGCC = 1;
 * @return {string}
 */
proto.Transaction.StatusCheckRequest.prototype.getTranstypejmorgcc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.StatusCheckRequest} returns this
 */
proto.Transaction.StatusCheckRequest.prototype.setTranstypejmorgcc = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.DonatedAmountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.DonatedAmountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.DonatedAmountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.DonatedAmountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    membertype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    memberid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.DonatedAmountRequest}
 */
proto.Transaction.DonatedAmountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.DonatedAmountRequest;
  return proto.Transaction.DonatedAmountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.DonatedAmountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.DonatedAmountRequest}
 */
proto.Transaction.DonatedAmountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMembertype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.DonatedAmountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.DonatedAmountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.DonatedAmountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.DonatedAmountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMembertype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string memberType = 1;
 * @return {string}
 */
proto.Transaction.DonatedAmountRequest.prototype.getMembertype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.DonatedAmountRequest} returns this
 */
proto.Transaction.DonatedAmountRequest.prototype.setMembertype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string memberId = 2;
 * @return {string}
 */
proto.Transaction.DonatedAmountRequest.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.DonatedAmountRequest} returns this
 */
proto.Transaction.DonatedAmountRequest.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.DontaedAmountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.DontaedAmountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.DontaedAmountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.DontaedAmountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.DontaedAmountResponse}
 */
proto.Transaction.DontaedAmountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.DontaedAmountResponse;
  return proto.Transaction.DontaedAmountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.DontaedAmountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.DontaedAmountResponse}
 */
proto.Transaction.DontaedAmountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.DontaedAmountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.DontaedAmountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.DontaedAmountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.DontaedAmountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.Transaction.DontaedAmountResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Transaction.DontaedAmountResponse} returns this
 */
proto.Transaction.DontaedAmountResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.Transaction.DontaedAmountResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.DontaedAmountResponse} returns this
 */
proto.Transaction.DontaedAmountResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.PostDataUssdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.PostDataUssdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.PostDataUssdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.PostDataUssdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cardtypeid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    networkid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    transamount: jspb.Message.getFieldWithDefault(msg, 4, ""),
    narration: jspb.Message.getFieldWithDefault(msg, 5, ""),
    transtypejmorgcc: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.PostDataUssdRequest}
 */
proto.Transaction.PostDataUssdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.PostDataUssdRequest;
  return proto.Transaction.PostDataUssdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.PostDataUssdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.PostDataUssdRequest}
 */
proto.Transaction.PostDataUssdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardtypeid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetworkid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransamount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNarration(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTranstypejmorgcc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.PostDataUssdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.PostDataUssdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.PostDataUssdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.PostDataUssdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCardtypeid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNetworkid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTransamount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNarration();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTranstypejmorgcc();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string mobileNumber = 1;
 * @return {string}
 */
proto.Transaction.PostDataUssdRequest.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.PostDataUssdRequest} returns this
 */
proto.Transaction.PostDataUssdRequest.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cardTypeId = 2;
 * @return {string}
 */
proto.Transaction.PostDataUssdRequest.prototype.getCardtypeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.PostDataUssdRequest} returns this
 */
proto.Transaction.PostDataUssdRequest.prototype.setCardtypeid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string networkId = 3;
 * @return {string}
 */
proto.Transaction.PostDataUssdRequest.prototype.getNetworkid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.PostDataUssdRequest} returns this
 */
proto.Transaction.PostDataUssdRequest.prototype.setNetworkid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string transAmount = 4;
 * @return {string}
 */
proto.Transaction.PostDataUssdRequest.prototype.getTransamount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.PostDataUssdRequest} returns this
 */
proto.Transaction.PostDataUssdRequest.prototype.setTransamount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string narration = 5;
 * @return {string}
 */
proto.Transaction.PostDataUssdRequest.prototype.getNarration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.PostDataUssdRequest} returns this
 */
proto.Transaction.PostDataUssdRequest.prototype.setNarration = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string transTypeJMOrGCC = 6;
 * @return {string}
 */
proto.Transaction.PostDataUssdRequest.prototype.getTranstypejmorgcc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.PostDataUssdRequest} returns this
 */
proto.Transaction.PostDataUssdRequest.prototype.setTranstypejmorgcc = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.UssdChargesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.UssdChargesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.UssdChargesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.UssdChargesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transamount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    networkid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.UssdChargesRequest}
 */
proto.Transaction.UssdChargesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.UssdChargesRequest;
  return proto.Transaction.UssdChargesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.UssdChargesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.UssdChargesRequest}
 */
proto.Transaction.UssdChargesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTransamount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetworkid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.UssdChargesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.UssdChargesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.UssdChargesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.UssdChargesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransamount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getNetworkid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional double transAmount = 1;
 * @return {number}
 */
proto.Transaction.UssdChargesRequest.prototype.getTransamount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Transaction.UssdChargesRequest} returns this
 */
proto.Transaction.UssdChargesRequest.prototype.setTransamount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string networkId = 2;
 * @return {string}
 */
proto.Transaction.UssdChargesRequest.prototype.getNetworkid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.UssdChargesRequest} returns this
 */
proto.Transaction.UssdChargesRequest.prototype.setNetworkid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mobileNumber = 3;
 * @return {string}
 */
proto.Transaction.UssdChargesRequest.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.UssdChargesRequest} returns this
 */
proto.Transaction.UssdChargesRequest.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.TransAdminRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.TransAdminRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.TransAdminRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.TransAdminRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transtypejmorggc: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.TransAdminRequest}
 */
proto.Transaction.TransAdminRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.TransAdminRequest;
  return proto.Transaction.TransAdminRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.TransAdminRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.TransAdminRequest}
 */
proto.Transaction.TransAdminRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTranstypejmorggc(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 4:
      var value = /** @type {!proto.Transaction.TransactionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.TransAdminRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.TransAdminRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.TransAdminRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.TransAdminRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTranstypejmorggc();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string transTypeJMOrGGC = 1;
 * @return {string}
 */
proto.Transaction.TransAdminRequest.prototype.getTranstypejmorggc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransAdminRequest} returns this
 */
proto.Transaction.TransAdminRequest.prototype.setTranstypejmorggc = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string startDate = 2;
 * @return {string}
 */
proto.Transaction.TransAdminRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransAdminRequest} returns this
 */
proto.Transaction.TransAdminRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string endDate = 3;
 * @return {string}
 */
proto.Transaction.TransAdminRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransAdminRequest} returns this
 */
proto.Transaction.TransAdminRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional TransactionStatus status = 4;
 * @return {!proto.Transaction.TransactionStatus}
 */
proto.Transaction.TransAdminRequest.prototype.getStatus = function() {
  return /** @type {!proto.Transaction.TransactionStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.Transaction.TransactionStatus} value
 * @return {!proto.Transaction.TransAdminRequest} returns this
 */
proto.Transaction.TransAdminRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.TransResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.TransResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.TransResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.TransResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    threedsurl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.TransResponse}
 */
proto.Transaction.TransResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.TransResponse;
  return proto.Transaction.TransResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.TransResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.TransResponse}
 */
proto.Transaction.TransResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setThreedsurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.TransResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.TransResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.TransResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.TransResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getThreedsurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.Transaction.TransResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Transaction.TransResponse} returns this
 */
proto.Transaction.TransResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.Transaction.TransResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransResponse} returns this
 */
proto.Transaction.TransResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string threedsurl = 3;
 * @return {string}
 */
proto.Transaction.TransResponse.prototype.getThreedsurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransResponse} returns this
 */
proto.Transaction.TransResponse.prototype.setThreedsurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.ChargesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.ChargesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.ChargesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.ChargesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transamount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    networkid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.ChargesRequest}
 */
proto.Transaction.ChargesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.ChargesRequest;
  return proto.Transaction.ChargesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.ChargesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.ChargesRequest}
 */
proto.Transaction.ChargesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTransamount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetworkid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.ChargesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.ChargesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.ChargesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.ChargesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransamount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getNetworkid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional double transAmount = 1;
 * @return {number}
 */
proto.Transaction.ChargesRequest.prototype.getTransamount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Transaction.ChargesRequest} returns this
 */
proto.Transaction.ChargesRequest.prototype.setTransamount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string networkId = 2;
 * @return {string}
 */
proto.Transaction.ChargesRequest.prototype.getNetworkid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.ChargesRequest} returns this
 */
proto.Transaction.ChargesRequest.prototype.setNetworkid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mobileNumber = 3;
 * @return {string}
 */
proto.Transaction.ChargesRequest.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.ChargesRequest} returns this
 */
proto.Transaction.ChargesRequest.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.ChargesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.ChargesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.ChargesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.ChargesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    charges: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.ChargesResponse}
 */
proto.Transaction.ChargesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.ChargesResponse;
  return proto.Transaction.ChargesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.ChargesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.ChargesResponse}
 */
proto.Transaction.ChargesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCharges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.ChargesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.ChargesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.ChargesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.ChargesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCharges();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.Transaction.ChargesResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Transaction.ChargesResponse} returns this
 */
proto.Transaction.ChargesResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.Transaction.ChargesResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.ChargesResponse} returns this
 */
proto.Transaction.ChargesResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double charges = 3;
 * @return {number}
 */
proto.Transaction.ChargesResponse.prototype.getCharges = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Transaction.ChargesResponse} returns this
 */
proto.Transaction.ChargesResponse.prototype.setCharges = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.KycRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.KycRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.KycRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.KycRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    networkid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.KycRequest}
 */
proto.Transaction.KycRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.KycRequest;
  return proto.Transaction.KycRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.KycRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.KycRequest}
 */
proto.Transaction.KycRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetworkid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.KycRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.KycRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.KycRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.KycRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNetworkid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string mobileNumber = 1;
 * @return {string}
 */
proto.Transaction.KycRequest.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.KycRequest} returns this
 */
proto.Transaction.KycRequest.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string networkId = 2;
 * @return {string}
 */
proto.Transaction.KycRequest.prototype.getNetworkid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.KycRequest} returns this
 */
proto.Transaction.KycRequest.prototype.setNetworkid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.KycResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.KycResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.KycResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.KycResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.KycResponse}
 */
proto.Transaction.KycResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.KycResponse;
  return proto.Transaction.KycResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.KycResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.KycResponse}
 */
proto.Transaction.KycResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.KycResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.KycResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.KycResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.KycResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.Transaction.KycResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Transaction.KycResponse} returns this
 */
proto.Transaction.KycResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.Transaction.KycResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.KycResponse} returns this
 */
proto.Transaction.KycResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.TransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.TransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.TransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.TransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    memberid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    membertype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.TransactionRequest}
 */
proto.Transaction.TransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.TransactionRequest;
  return proto.Transaction.TransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.TransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.TransactionRequest}
 */
proto.Transaction.TransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMembertype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.TransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.TransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.TransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.TransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMembertype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string memberId = 1;
 * @return {string}
 */
proto.Transaction.TransactionRequest.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransactionRequest} returns this
 */
proto.Transaction.TransactionRequest.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string memberType = 2;
 * @return {string}
 */
proto.Transaction.TransactionRequest.prototype.getMembertype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransactionRequest} returns this
 */
proto.Transaction.TransactionRequest.prototype.setMembertype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string startDate = 3;
 * @return {string}
 */
proto.Transaction.TransactionRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransactionRequest} returns this
 */
proto.Transaction.TransactionRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string endDate = 4;
 * @return {string}
 */
proto.Transaction.TransactionRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransactionRequest} returns this
 */
proto.Transaction.TransactionRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.TransactionHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.TransactionHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.TransactionHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.TransactionHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    memberid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    membertype: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.TransactionHistoryRequest}
 */
proto.Transaction.TransactionHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.TransactionHistoryRequest;
  return proto.Transaction.TransactionHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.TransactionHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.TransactionHistoryRequest}
 */
proto.Transaction.TransactionHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMembertype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.TransactionHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.TransactionHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.TransactionHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.TransactionHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMembertype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string memberId = 1;
 * @return {string}
 */
proto.Transaction.TransactionHistoryRequest.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransactionHistoryRequest} returns this
 */
proto.Transaction.TransactionHistoryRequest.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string memberType = 2;
 * @return {string}
 */
proto.Transaction.TransactionHistoryRequest.prototype.getMembertype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransactionHistoryRequest} returns this
 */
proto.Transaction.TransactionHistoryRequest.prototype.setMembertype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.TransactionData.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.TransactionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.TransactionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.TransactionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    membername: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, ""),
    date: jspb.Message.getFieldWithDefault(msg, 6, ""),
    paymentname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    paymentaccountnumberormomonumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    transstatus: jspb.Message.getFieldWithDefault(msg, 9, ""),
    paymentmethod: jspb.Message.getFieldWithDefault(msg, 10, ""),
    agentname: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.TransactionData}
 */
proto.Transaction.TransactionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.TransactionData;
  return proto.Transaction.TransactionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.TransactionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.TransactionData}
 */
proto.Transaction.TransactionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMembername(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentname(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentaccountnumberormomonumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransstatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentmethod(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.TransactionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.TransactionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.TransactionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.TransactionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMembername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPaymentname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPaymentaccountnumberormomonumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTransstatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPaymentmethod();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAgentname();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string transactionId = 1;
 * @return {string}
 */
proto.Transaction.TransactionData.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransactionData} returns this
 */
proto.Transaction.TransactionData.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string memberName = 2;
 * @return {string}
 */
proto.Transaction.TransactionData.prototype.getMembername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransactionData} returns this
 */
proto.Transaction.TransactionData.prototype.setMembername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double amount = 3;
 * @return {number}
 */
proto.Transaction.TransactionData.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Transaction.TransactionData} returns this
 */
proto.Transaction.TransactionData.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.Transaction.TransactionData.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransactionData} returns this
 */
proto.Transaction.TransactionData.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.Transaction.TransactionData.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransactionData} returns this
 */
proto.Transaction.TransactionData.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string date = 6;
 * @return {string}
 */
proto.Transaction.TransactionData.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransactionData} returns this
 */
proto.Transaction.TransactionData.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string paymentName = 7;
 * @return {string}
 */
proto.Transaction.TransactionData.prototype.getPaymentname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransactionData} returns this
 */
proto.Transaction.TransactionData.prototype.setPaymentname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string paymentAccountNumberOrMomoNumber = 8;
 * @return {string}
 */
proto.Transaction.TransactionData.prototype.getPaymentaccountnumberormomonumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransactionData} returns this
 */
proto.Transaction.TransactionData.prototype.setPaymentaccountnumberormomonumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string transStatus = 9;
 * @return {string}
 */
proto.Transaction.TransactionData.prototype.getTransstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransactionData} returns this
 */
proto.Transaction.TransactionData.prototype.setTransstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string paymentMethod = 10;
 * @return {string}
 */
proto.Transaction.TransactionData.prototype.getPaymentmethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransactionData} returns this
 */
proto.Transaction.TransactionData.prototype.setPaymentmethod = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string agentName = 11;
 * @return {string}
 */
proto.Transaction.TransactionData.prototype.getAgentname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TransactionData} returns this
 */
proto.Transaction.TransactionData.prototype.setAgentname = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Transaction.TransactionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.TransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.TransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.TransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.TransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    translistList: jspb.Message.toObjectList(msg.getTranslistList(),
    proto.Transaction.TransactionData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.TransactionResponse}
 */
proto.Transaction.TransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.TransactionResponse;
  return proto.Transaction.TransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.TransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.TransactionResponse}
 */
proto.Transaction.TransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Transaction.TransactionData;
      reader.readMessage(value,proto.Transaction.TransactionData.deserializeBinaryFromReader);
      msg.addTranslist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.TransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.TransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.TransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.TransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTranslistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Transaction.TransactionData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TransactionData transList = 1;
 * @return {!Array<!proto.Transaction.TransactionData>}
 */
proto.Transaction.TransactionResponse.prototype.getTranslistList = function() {
  return /** @type{!Array<!proto.Transaction.TransactionData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Transaction.TransactionData, 1));
};


/**
 * @param {!Array<!proto.Transaction.TransactionData>} value
 * @return {!proto.Transaction.TransactionResponse} returns this
*/
proto.Transaction.TransactionResponse.prototype.setTranslistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Transaction.TransactionData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Transaction.TransactionData}
 */
proto.Transaction.TransactionResponse.prototype.addTranslist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Transaction.TransactionData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Transaction.TransactionResponse} returns this
 */
proto.Transaction.TransactionResponse.prototype.clearTranslistList = function() {
  return this.setTranslistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.StatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.StatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.StatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.StatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.StatusRequest}
 */
proto.Transaction.StatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.StatusRequest;
  return proto.Transaction.StatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.StatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.StatusRequest}
 */
proto.Transaction.StatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.StatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.StatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.StatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.StatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string transactionId = 1;
 * @return {string}
 */
proto.Transaction.StatusRequest.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.StatusRequest} returns this
 */
proto.Transaction.StatusRequest.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.StatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.StatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.StatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.StatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    transstatus: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.StatusResponse}
 */
proto.Transaction.StatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.StatusResponse;
  return proto.Transaction.StatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.StatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.StatusResponse}
 */
proto.Transaction.StatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransstatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.StatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.StatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.StatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.StatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getTransstatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.Transaction.StatusResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Transaction.StatusResponse} returns this
 */
proto.Transaction.StatusResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string transStatus = 2;
 * @return {string}
 */
proto.Transaction.StatusResponse.prototype.getTransstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.StatusResponse} returns this
 */
proto.Transaction.StatusResponse.prototype.setTransstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.Transaction.StatusResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.StatusResponse} returns this
 */
proto.Transaction.StatusResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.CardTypePerTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.CardTypePerTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.CardTypePerTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.CardTypePerTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    memberid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.CardTypePerTransactionRequest}
 */
proto.Transaction.CardTypePerTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.CardTypePerTransactionRequest;
  return proto.Transaction.CardTypePerTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.CardTypePerTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.CardTypePerTransactionRequest}
 */
proto.Transaction.CardTypePerTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.CardTypePerTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.CardTypePerTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.CardTypePerTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.CardTypePerTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string memberId = 1;
 * @return {string}
 */
proto.Transaction.CardTypePerTransactionRequest.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.CardTypePerTransactionRequest} returns this
 */
proto.Transaction.CardTypePerTransactionRequest.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.CardTypePerTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.CardTypePerTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.CardTypePerTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.CardTypePerTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardtypeid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cardtypedescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cardtypename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    totaldonation: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.CardTypePerTransactionResponse}
 */
proto.Transaction.CardTypePerTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.CardTypePerTransactionResponse;
  return proto.Transaction.CardTypePerTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.CardTypePerTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.CardTypePerTransactionResponse}
 */
proto.Transaction.CardTypePerTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardtypeid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardtypedescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardtypename(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotaldonation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.CardTypePerTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.CardTypePerTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.CardTypePerTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.CardTypePerTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardtypeid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCardtypedescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCardtypename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTotaldonation();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string cardTypeId = 1;
 * @return {string}
 */
proto.Transaction.CardTypePerTransactionResponse.prototype.getCardtypeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.CardTypePerTransactionResponse} returns this
 */
proto.Transaction.CardTypePerTransactionResponse.prototype.setCardtypeid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cardTypeDescription = 2;
 * @return {string}
 */
proto.Transaction.CardTypePerTransactionResponse.prototype.getCardtypedescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.CardTypePerTransactionResponse} returns this
 */
proto.Transaction.CardTypePerTransactionResponse.prototype.setCardtypedescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string cardTypeName = 3;
 * @return {string}
 */
proto.Transaction.CardTypePerTransactionResponse.prototype.getCardtypename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.CardTypePerTransactionResponse} returns this
 */
proto.Transaction.CardTypePerTransactionResponse.prototype.setCardtypename = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double totalDonation = 4;
 * @return {number}
 */
proto.Transaction.CardTypePerTransactionResponse.prototype.getTotaldonation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Transaction.CardTypePerTransactionResponse} returns this
 */
proto.Transaction.CardTypePerTransactionResponse.prototype.setTotaldonation = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.TotalTransactionDoneByAgentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.TotalTransactionDoneByAgentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.TotalTransactionDoneByAgentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.TotalTransactionDoneByAgentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.TotalTransactionDoneByAgentRequest}
 */
proto.Transaction.TotalTransactionDoneByAgentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.TotalTransactionDoneByAgentRequest;
  return proto.Transaction.TotalTransactionDoneByAgentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.TotalTransactionDoneByAgentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.TotalTransactionDoneByAgentRequest}
 */
proto.Transaction.TotalTransactionDoneByAgentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.TotalTransactionDoneByAgentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.TotalTransactionDoneByAgentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.TotalTransactionDoneByAgentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.TotalTransactionDoneByAgentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string agentId = 1;
 * @return {string}
 */
proto.Transaction.TotalTransactionDoneByAgentRequest.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.TotalTransactionDoneByAgentRequest} returns this
 */
proto.Transaction.TotalTransactionDoneByAgentRequest.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.TotalTransactionDoneByAgentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.TotalTransactionDoneByAgentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalamountggc: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    totalcommissionggc: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalamountjm: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalcommissionjm: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalamountggcjm: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    totalcommissionggcjm: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.TotalTransactionDoneByAgentResponse}
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.TotalTransactionDoneByAgentResponse;
  return proto.Transaction.TotalTransactionDoneByAgentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.TotalTransactionDoneByAgentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.TotalTransactionDoneByAgentResponse}
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalamountggc(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalcommissionggc(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalamountjm(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalcommissionjm(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalamountggcjm(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalcommissionggcjm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.TotalTransactionDoneByAgentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.TotalTransactionDoneByAgentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalamountggc();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTotalcommissionggc();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalamountjm();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTotalcommissionjm();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTotalamountggcjm();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getTotalcommissionggcjm();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional double totalAmountGGC = 1;
 * @return {number}
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.prototype.getTotalamountggc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Transaction.TotalTransactionDoneByAgentResponse} returns this
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.prototype.setTotalamountggc = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double totalCommissionGGC = 2;
 * @return {number}
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.prototype.getTotalcommissionggc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Transaction.TotalTransactionDoneByAgentResponse} returns this
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.prototype.setTotalcommissionggc = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double totalAmountJM = 3;
 * @return {number}
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.prototype.getTotalamountjm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Transaction.TotalTransactionDoneByAgentResponse} returns this
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.prototype.setTotalamountjm = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double totalCommissionJM = 4;
 * @return {number}
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.prototype.getTotalcommissionjm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Transaction.TotalTransactionDoneByAgentResponse} returns this
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.prototype.setTotalcommissionjm = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double totalAmountGGCJM = 5;
 * @return {number}
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.prototype.getTotalamountggcjm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Transaction.TotalTransactionDoneByAgentResponse} returns this
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.prototype.setTotalamountggcjm = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double totalCommissionGGCJM = 6;
 * @return {number}
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.prototype.getTotalcommissionggcjm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Transaction.TotalTransactionDoneByAgentResponse} returns this
 */
proto.Transaction.TotalTransactionDoneByAgentResponse.prototype.setTotalcommissionggcjm = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transaction.StatusCheckResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Transaction.StatusCheckResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transaction.StatusCheckResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.StatusCheckResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transaction.StatusCheckResponse}
 */
proto.Transaction.StatusCheckResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transaction.StatusCheckResponse;
  return proto.Transaction.StatusCheckResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transaction.StatusCheckResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transaction.StatusCheckResponse}
 */
proto.Transaction.StatusCheckResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transaction.StatusCheckResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transaction.StatusCheckResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transaction.StatusCheckResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transaction.StatusCheckResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.Transaction.StatusCheckResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Transaction.StatusCheckResponse} returns this
 */
proto.Transaction.StatusCheckResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.Transaction.StatusCheckResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Transaction.StatusCheckResponse} returns this
 */
proto.Transaction.StatusCheckResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.Transaction.TransactionTypeMonthlyOrUpgrade = {
  UPGRADE: 0,
  MONTHLY_CONTRIBUTION: 1,
  CARD_REPRINT: 2
};

/**
 * @enum {number}
 */
proto.Transaction.TransactionStatus = {
  SUCCESSFUL: 0,
  PENDING: 1,
  FAILED: 2,
  ALL: 3
};

goog.object.extend(exports, proto.Transaction);
