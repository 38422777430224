// source: AgentMobileWeb.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.AgentMobileWeb.AcctCreationStatus', null, global);
goog.exportSymbol('proto.AgentMobileWeb.Agent', null, global);
goog.exportSymbol('proto.AgentMobileWeb.AgentEmptyRequest', null, global);
goog.exportSymbol('proto.AgentMobileWeb.AgentRequest', null, global);
goog.exportSymbol('proto.AgentMobileWeb.AgentResponse', null, global);
goog.exportSymbol('proto.AgentMobileWeb.AgentTransactionData', null, global);
goog.exportSymbol('proto.AgentMobileWeb.AgentUpdateRequest', null, global);
goog.exportSymbol('proto.AgentMobileWeb.ChangePinRequest', null, global);
goog.exportSymbol('proto.AgentMobileWeb.ChangePinResponse', null, global);
goog.exportSymbol('proto.AgentMobileWeb.CreateAgentRequest', null, global);
goog.exportSymbol('proto.AgentMobileWeb.CreateAgentResponse', null, global);
goog.exportSymbol('proto.AgentMobileWeb.DonationCollectedRequest', null, global);
goog.exportSymbol('proto.AgentMobileWeb.DonationCollectedResponse', null, global);
goog.exportSymbol('proto.AgentMobileWeb.ListOfAgentsResponse', null, global);
goog.exportSymbol('proto.AgentMobileWeb.LoginRequest', null, global);
goog.exportSymbol('proto.AgentMobileWeb.LoginResponse', null, global);
goog.exportSymbol('proto.AgentMobileWeb.MemberCreatedByAgent', null, global);
goog.exportSymbol('proto.AgentMobileWeb.MemberCreatedByAgentRequest', null, global);
goog.exportSymbol('proto.AgentMobileWeb.MembersCreatedByAgentResponse', null, global);
goog.exportSymbol('proto.AgentMobileWeb.PasswordResetRequest', null, global);
goog.exportSymbol('proto.AgentMobileWeb.TotalTransactionAndCommission', null, global);
goog.exportSymbol('proto.AgentMobileWeb.TotalTransactionCommissionRequest', null, global);
goog.exportSymbol('proto.AgentMobileWeb.TotalTransactionCommissionResponse', null, global);
goog.exportSymbol('proto.AgentMobileWeb.TransStatus', null, global);
goog.exportSymbol('proto.AgentMobileWeb.TransactionCommissionDetailData', null, global);
goog.exportSymbol('proto.AgentMobileWeb.TransactionCommissionDetailResponse', null, global);
goog.exportSymbol('proto.AgentMobileWeb.TransactionListAgentRequest', null, global);
goog.exportSymbol('proto.AgentMobileWeb.TransactionListResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.CreateAgentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.CreateAgentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.CreateAgentRequest.displayName = 'proto.AgentMobileWeb.CreateAgentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.CreateAgentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.CreateAgentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.CreateAgentResponse.displayName = 'proto.AgentMobileWeb.CreateAgentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.ChangePinRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.ChangePinRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.ChangePinRequest.displayName = 'proto.AgentMobileWeb.ChangePinRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.ChangePinResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.ChangePinResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.ChangePinResponse.displayName = 'proto.AgentMobileWeb.ChangePinResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.LoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.LoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.LoginRequest.displayName = 'proto.AgentMobileWeb.LoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.LoginResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.LoginResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.LoginResponse.displayName = 'proto.AgentMobileWeb.LoginResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.MemberCreatedByAgentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.MemberCreatedByAgentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.MemberCreatedByAgentRequest.displayName = 'proto.AgentMobileWeb.MemberCreatedByAgentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.MemberCreatedByAgent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.MemberCreatedByAgent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.MemberCreatedByAgent.displayName = 'proto.AgentMobileWeb.MemberCreatedByAgent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.MembersCreatedByAgentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AgentMobileWeb.MembersCreatedByAgentResponse.repeatedFields_, null);
};
goog.inherits(proto.AgentMobileWeb.MembersCreatedByAgentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.MembersCreatedByAgentResponse.displayName = 'proto.AgentMobileWeb.MembersCreatedByAgentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.TransactionListAgentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.TransactionListAgentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.TransactionListAgentRequest.displayName = 'proto.AgentMobileWeb.TransactionListAgentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.DonationCollectedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.DonationCollectedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.DonationCollectedRequest.displayName = 'proto.AgentMobileWeb.DonationCollectedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.AgentTransactionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.AgentTransactionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.AgentTransactionData.displayName = 'proto.AgentMobileWeb.AgentTransactionData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.TransactionListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AgentMobileWeb.TransactionListResponse.repeatedFields_, null);
};
goog.inherits(proto.AgentMobileWeb.TransactionListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.TransactionListResponse.displayName = 'proto.AgentMobileWeb.TransactionListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.DonationCollectedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.DonationCollectedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.DonationCollectedResponse.displayName = 'proto.AgentMobileWeb.DonationCollectedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.AgentEmptyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.AgentEmptyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.AgentEmptyRequest.displayName = 'proto.AgentMobileWeb.AgentEmptyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.PasswordResetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.PasswordResetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.PasswordResetRequest.displayName = 'proto.AgentMobileWeb.PasswordResetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.AgentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.AgentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.AgentResponse.displayName = 'proto.AgentMobileWeb.AgentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.AgentUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.AgentUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.AgentUpdateRequest.displayName = 'proto.AgentMobileWeb.AgentUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.AgentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.AgentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.AgentRequest.displayName = 'proto.AgentMobileWeb.AgentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.Agent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.Agent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.Agent.displayName = 'proto.AgentMobileWeb.Agent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.ListOfAgentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AgentMobileWeb.ListOfAgentsResponse.repeatedFields_, null);
};
goog.inherits(proto.AgentMobileWeb.ListOfAgentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.ListOfAgentsResponse.displayName = 'proto.AgentMobileWeb.ListOfAgentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.TotalTransactionCommissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.TotalTransactionCommissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.TotalTransactionCommissionRequest.displayName = 'proto.AgentMobileWeb.TotalTransactionCommissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.TotalTransactionAndCommission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.TotalTransactionAndCommission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.TotalTransactionAndCommission.displayName = 'proto.AgentMobileWeb.TotalTransactionAndCommission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.TotalTransactionCommissionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AgentMobileWeb.TotalTransactionCommissionResponse.repeatedFields_, null);
};
goog.inherits(proto.AgentMobileWeb.TotalTransactionCommissionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.TotalTransactionCommissionResponse.displayName = 'proto.AgentMobileWeb.TotalTransactionCommissionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.TransactionCommissionDetailData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentMobileWeb.TransactionCommissionDetailData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.TransactionCommissionDetailData.displayName = 'proto.AgentMobileWeb.TransactionCommissionDetailData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentMobileWeb.TransactionCommissionDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AgentMobileWeb.TransactionCommissionDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.AgentMobileWeb.TransactionCommissionDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AgentMobileWeb.TransactionCommissionDetailResponse.displayName = 'proto.AgentMobileWeb.TransactionCommissionDetailResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.CreateAgentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.CreateAgentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.CreateAgentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fullname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    emailaddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 4, ""),
    age: jspb.Message.getFieldWithDefault(msg, 5, 0),
    countryofresidence: jspb.Message.getFieldWithDefault(msg, 6, ""),
    regionid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    constituencyid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    industry: jspb.Message.getFieldWithDefault(msg, 9, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 10, ""),
    ndcmemberidno: jspb.Message.getFieldWithDefault(msg, 11, ""),
    agentid: jspb.Message.getFieldWithDefault(msg, 12, ""),
    pin: jspb.Message.getFieldWithDefault(msg, 13, ""),
    mobielwebussd: jspb.Message.getFieldWithDefault(msg, 14, ""),
    agerageid: jspb.Message.getFieldWithDefault(msg, 15, ""),
    displaynameoncard: jspb.Message.getFieldWithDefault(msg, 16, ""),
    cardpickuplocation: jspb.Message.getFieldWithDefault(msg, 17, ""),
    cardtypeid: jspb.Message.getFieldWithDefault(msg, 18, ""),
    accountcreationstatus: jspb.Message.getFieldWithDefault(msg, 19, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.CreateAgentRequest}
 */
proto.AgentMobileWeb.CreateAgentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.CreateAgentRequest;
  return proto.AgentMobileWeb.CreateAgentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.CreateAgentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.CreateAgentRequest}
 */
proto.AgentMobileWeb.CreateAgentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailaddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGender(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAge(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryofresidence(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setConstituencyid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndustry(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setNdcmemberidno(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPin(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobielwebussd(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgerageid(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplaynameoncard(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardpickuplocation(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardtypeid(value);
      break;
    case 19:
      var value = /** @type {!proto.AgentMobileWeb.AcctCreationStatus} */ (reader.readEnum());
      msg.setAccountcreationstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.CreateAgentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.CreateAgentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.CreateAgentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFullname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmailaddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGender();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAge();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCountryofresidence();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRegionid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getConstituencyid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIndustry();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getNdcmemberidno();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPin();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getMobielwebussd();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAgerageid();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDisplaynameoncard();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCardpickuplocation();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCardtypeid();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getAccountcreationstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
};


/**
 * optional string fullName = 1;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getFullname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setFullname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mobileNumber = 2;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string emailAddress = 3;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getEmailaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setEmailaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string gender = 4;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getGender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setGender = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 age = 5;
 * @return {number}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getAge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setAge = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string countryOfResidence = 6;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getCountryofresidence = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setCountryofresidence = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string regionId = 7;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getRegionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setRegionid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string constituencyId = 8;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getConstituencyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setConstituencyid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string industry = 9;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getIndustry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setIndustry = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string occupation = 10;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string NDCMemberIdNo = 11;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getNdcmemberidno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setNdcmemberidno = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string agentId = 12;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string PIN = 13;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setPin = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string mobielWebUssd = 14;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getMobielwebussd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setMobielwebussd = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string ageRageId = 15;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getAgerageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setAgerageid = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string displayNameOnCard = 16;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getDisplaynameoncard = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setDisplaynameoncard = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string cardPickUpLocation = 17;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getCardpickuplocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setCardpickuplocation = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string cardTypeId = 18;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getCardtypeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setCardtypeid = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional AcctCreationStatus accountCreationStatus = 19;
 * @return {!proto.AgentMobileWeb.AcctCreationStatus}
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.getAccountcreationstatus = function() {
  return /** @type {!proto.AgentMobileWeb.AcctCreationStatus} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.AgentMobileWeb.AcctCreationStatus} value
 * @return {!proto.AgentMobileWeb.CreateAgentRequest} returns this
 */
proto.AgentMobileWeb.CreateAgentRequest.prototype.setAccountcreationstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.CreateAgentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.CreateAgentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.CreateAgentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.CreateAgentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    agentid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cardnumber: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.CreateAgentResponse}
 */
proto.AgentMobileWeb.CreateAgentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.CreateAgentResponse;
  return proto.AgentMobileWeb.CreateAgentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.CreateAgentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.CreateAgentResponse}
 */
proto.AgentMobileWeb.CreateAgentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.CreateAgentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.CreateAgentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.CreateAgentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.CreateAgentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCardnumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.AgentMobileWeb.CreateAgentResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AgentMobileWeb.CreateAgentResponse} returns this
 */
proto.AgentMobileWeb.CreateAgentResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentResponse} returns this
 */
proto.AgentMobileWeb.CreateAgentResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string agentId = 3;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentResponse.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentResponse} returns this
 */
proto.AgentMobileWeb.CreateAgentResponse.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cardNumber = 4;
 * @return {string}
 */
proto.AgentMobileWeb.CreateAgentResponse.prototype.getCardnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.CreateAgentResponse} returns this
 */
proto.AgentMobileWeb.CreateAgentResponse.prototype.setCardnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.ChangePinRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.ChangePinRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.ChangePinRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.ChangePinRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    oldpin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newpin: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.ChangePinRequest}
 */
proto.AgentMobileWeb.ChangePinRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.ChangePinRequest;
  return proto.AgentMobileWeb.ChangePinRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.ChangePinRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.ChangePinRequest}
 */
proto.AgentMobileWeb.ChangePinRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldpin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewpin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.ChangePinRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.ChangePinRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.ChangePinRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.ChangePinRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOldpin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewpin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string mobileNumber = 1;
 * @return {string}
 */
proto.AgentMobileWeb.ChangePinRequest.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.ChangePinRequest} returns this
 */
proto.AgentMobileWeb.ChangePinRequest.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string oldPin = 2;
 * @return {string}
 */
proto.AgentMobileWeb.ChangePinRequest.prototype.getOldpin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.ChangePinRequest} returns this
 */
proto.AgentMobileWeb.ChangePinRequest.prototype.setOldpin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string newPin = 3;
 * @return {string}
 */
proto.AgentMobileWeb.ChangePinRequest.prototype.getNewpin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.ChangePinRequest} returns this
 */
proto.AgentMobileWeb.ChangePinRequest.prototype.setNewpin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.ChangePinResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.ChangePinResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.ChangePinResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.ChangePinResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.ChangePinResponse}
 */
proto.AgentMobileWeb.ChangePinResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.ChangePinResponse;
  return proto.AgentMobileWeb.ChangePinResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.ChangePinResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.ChangePinResponse}
 */
proto.AgentMobileWeb.ChangePinResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.ChangePinResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.ChangePinResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.ChangePinResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.ChangePinResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.AgentMobileWeb.ChangePinResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AgentMobileWeb.ChangePinResponse} returns this
 */
proto.AgentMobileWeb.ChangePinResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.AgentMobileWeb.ChangePinResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.ChangePinResponse} returns this
 */
proto.AgentMobileWeb.ChangePinResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.LoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.LoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.LoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.LoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pin: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.LoginRequest}
 */
proto.AgentMobileWeb.LoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.LoginRequest;
  return proto.AgentMobileWeb.LoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.LoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.LoginRequest}
 */
proto.AgentMobileWeb.LoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.LoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.LoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.LoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.LoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string mobileNumber = 1;
 * @return {string}
 */
proto.AgentMobileWeb.LoginRequest.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.LoginRequest} returns this
 */
proto.AgentMobileWeb.LoginRequest.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pin = 2;
 * @return {string}
 */
proto.AgentMobileWeb.LoginRequest.prototype.getPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.LoginRequest} returns this
 */
proto.AgentMobileWeb.LoginRequest.prototype.setPin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.LoginResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.LoginResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.LoginResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.LoginResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    agentname: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.LoginResponse}
 */
proto.AgentMobileWeb.LoginResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.LoginResponse;
  return proto.AgentMobileWeb.LoginResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.LoginResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.LoginResponse}
 */
proto.AgentMobileWeb.LoginResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.LoginResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.LoginResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.LoginResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.LoginResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAgentname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.AgentMobileWeb.LoginResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AgentMobileWeb.LoginResponse} returns this
 */
proto.AgentMobileWeb.LoginResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.AgentMobileWeb.LoginResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.LoginResponse} returns this
 */
proto.AgentMobileWeb.LoginResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string agentName = 3;
 * @return {string}
 */
proto.AgentMobileWeb.LoginResponse.prototype.getAgentname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.LoginResponse} returns this
 */
proto.AgentMobileWeb.LoginResponse.prototype.setAgentname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.MemberCreatedByAgentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.MemberCreatedByAgentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.MemberCreatedByAgentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.MemberCreatedByAgentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.MemberCreatedByAgentRequest}
 */
proto.AgentMobileWeb.MemberCreatedByAgentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.MemberCreatedByAgentRequest;
  return proto.AgentMobileWeb.MemberCreatedByAgentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.MemberCreatedByAgentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.MemberCreatedByAgentRequest}
 */
proto.AgentMobileWeb.MemberCreatedByAgentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.MemberCreatedByAgentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.MemberCreatedByAgentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.MemberCreatedByAgentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.MemberCreatedByAgentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string agentId = 1;
 * @return {string}
 */
proto.AgentMobileWeb.MemberCreatedByAgentRequest.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.MemberCreatedByAgentRequest} returns this
 */
proto.AgentMobileWeb.MemberCreatedByAgentRequest.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.MemberCreatedByAgent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.MemberCreatedByAgent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.MemberCreatedByAgent.toObject = function(includeInstance, msg) {
  var f, obj = {
    memberid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    membername: jspb.Message.getFieldWithDefault(msg, 2, ""),
    registrationdate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cardnumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cardamount: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    cardpickuplocation: jspb.Message.getFieldWithDefault(msg, 6, ""),
    cardname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cardid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    mobilenumberoremailaddress: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.MemberCreatedByAgent}
 */
proto.AgentMobileWeb.MemberCreatedByAgent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.MemberCreatedByAgent;
  return proto.AgentMobileWeb.MemberCreatedByAgent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.MemberCreatedByAgent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.MemberCreatedByAgent}
 */
proto.AgentMobileWeb.MemberCreatedByAgent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMembername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationdate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardnumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCardamount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardpickuplocation(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardname(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumberoremailaddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.MemberCreatedByAgent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.MemberCreatedByAgent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.MemberCreatedByAgent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMembername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRegistrationdate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCardnumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCardamount();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getCardpickuplocation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCardname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCardid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMobilenumberoremailaddress();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string memberId = 1;
 * @return {string}
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.MemberCreatedByAgent} returns this
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string memberName = 2;
 * @return {string}
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.getMembername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.MemberCreatedByAgent} returns this
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.setMembername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string registrationDate = 3;
 * @return {string}
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.getRegistrationdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.MemberCreatedByAgent} returns this
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.setRegistrationdate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cardNumber = 4;
 * @return {string}
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.getCardnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.MemberCreatedByAgent} returns this
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.setCardnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double cardAmount = 5;
 * @return {number}
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.getCardamount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.AgentMobileWeb.MemberCreatedByAgent} returns this
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.setCardamount = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string cardPickUpLocation = 6;
 * @return {string}
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.getCardpickuplocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.MemberCreatedByAgent} returns this
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.setCardpickuplocation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string cardName = 7;
 * @return {string}
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.getCardname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.MemberCreatedByAgent} returns this
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.setCardname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string cardId = 8;
 * @return {string}
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.getCardid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.MemberCreatedByAgent} returns this
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.setCardid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string mobileNumberOrEmailAddress = 9;
 * @return {string}
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.getMobilenumberoremailaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.MemberCreatedByAgent} returns this
 */
proto.AgentMobileWeb.MemberCreatedByAgent.prototype.setMobilenumberoremailaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AgentMobileWeb.MembersCreatedByAgentResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.MembersCreatedByAgentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.MembersCreatedByAgentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.MembersCreatedByAgentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.MembersCreatedByAgentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    membersList: jspb.Message.toObjectList(msg.getMembersList(),
    proto.AgentMobileWeb.MemberCreatedByAgent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.MembersCreatedByAgentResponse}
 */
proto.AgentMobileWeb.MembersCreatedByAgentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.MembersCreatedByAgentResponse;
  return proto.AgentMobileWeb.MembersCreatedByAgentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.MembersCreatedByAgentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.MembersCreatedByAgentResponse}
 */
proto.AgentMobileWeb.MembersCreatedByAgentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.AgentMobileWeb.MemberCreatedByAgent;
      reader.readMessage(value,proto.AgentMobileWeb.MemberCreatedByAgent.deserializeBinaryFromReader);
      msg.addMembers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.MembersCreatedByAgentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.MembersCreatedByAgentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.MembersCreatedByAgentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.MembersCreatedByAgentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMembersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.AgentMobileWeb.MemberCreatedByAgent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MemberCreatedByAgent members = 1;
 * @return {!Array<!proto.AgentMobileWeb.MemberCreatedByAgent>}
 */
proto.AgentMobileWeb.MembersCreatedByAgentResponse.prototype.getMembersList = function() {
  return /** @type{!Array<!proto.AgentMobileWeb.MemberCreatedByAgent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AgentMobileWeb.MemberCreatedByAgent, 1));
};


/**
 * @param {!Array<!proto.AgentMobileWeb.MemberCreatedByAgent>} value
 * @return {!proto.AgentMobileWeb.MembersCreatedByAgentResponse} returns this
*/
proto.AgentMobileWeb.MembersCreatedByAgentResponse.prototype.setMembersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.AgentMobileWeb.MemberCreatedByAgent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AgentMobileWeb.MemberCreatedByAgent}
 */
proto.AgentMobileWeb.MembersCreatedByAgentResponse.prototype.addMembers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.AgentMobileWeb.MemberCreatedByAgent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AgentMobileWeb.MembersCreatedByAgentResponse} returns this
 */
proto.AgentMobileWeb.MembersCreatedByAgentResponse.prototype.clearMembersList = function() {
  return this.setMembersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.TransactionListAgentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.TransactionListAgentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.TransactionListAgentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.TransactionListAgentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    membertype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.TransactionListAgentRequest}
 */
proto.AgentMobileWeb.TransactionListAgentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.TransactionListAgentRequest;
  return proto.AgentMobileWeb.TransactionListAgentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.TransactionListAgentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.TransactionListAgentRequest}
 */
proto.AgentMobileWeb.TransactionListAgentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMembertype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 5:
      var value = /** @type {!proto.AgentMobileWeb.TransStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.TransactionListAgentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.TransactionListAgentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.TransactionListAgentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.TransactionListAgentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMembertype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string agentId = 1;
 * @return {string}
 */
proto.AgentMobileWeb.TransactionListAgentRequest.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.TransactionListAgentRequest} returns this
 */
proto.AgentMobileWeb.TransactionListAgentRequest.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string memberType = 2;
 * @return {string}
 */
proto.AgentMobileWeb.TransactionListAgentRequest.prototype.getMembertype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.TransactionListAgentRequest} returns this
 */
proto.AgentMobileWeb.TransactionListAgentRequest.prototype.setMembertype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string startDate = 3;
 * @return {string}
 */
proto.AgentMobileWeb.TransactionListAgentRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.TransactionListAgentRequest} returns this
 */
proto.AgentMobileWeb.TransactionListAgentRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string endDate = 4;
 * @return {string}
 */
proto.AgentMobileWeb.TransactionListAgentRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.TransactionListAgentRequest} returns this
 */
proto.AgentMobileWeb.TransactionListAgentRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional TransStatus status = 5;
 * @return {!proto.AgentMobileWeb.TransStatus}
 */
proto.AgentMobileWeb.TransactionListAgentRequest.prototype.getStatus = function() {
  return /** @type {!proto.AgentMobileWeb.TransStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.AgentMobileWeb.TransStatus} value
 * @return {!proto.AgentMobileWeb.TransactionListAgentRequest} returns this
 */
proto.AgentMobileWeb.TransactionListAgentRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.DonationCollectedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.DonationCollectedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.DonationCollectedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.DonationCollectedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    membertype: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.DonationCollectedRequest}
 */
proto.AgentMobileWeb.DonationCollectedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.DonationCollectedRequest;
  return proto.AgentMobileWeb.DonationCollectedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.DonationCollectedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.DonationCollectedRequest}
 */
proto.AgentMobileWeb.DonationCollectedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMembertype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.DonationCollectedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.DonationCollectedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.DonationCollectedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.DonationCollectedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMembertype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string agentId = 1;
 * @return {string}
 */
proto.AgentMobileWeb.DonationCollectedRequest.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.DonationCollectedRequest} returns this
 */
proto.AgentMobileWeb.DonationCollectedRequest.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string memberType = 2;
 * @return {string}
 */
proto.AgentMobileWeb.DonationCollectedRequest.prototype.getMembertype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.DonationCollectedRequest} returns this
 */
proto.AgentMobileWeb.DonationCollectedRequest.prototype.setMembertype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.AgentTransactionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.AgentTransactionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.AgentTransactionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    membername: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transamount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    cardid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, ""),
    date: jspb.Message.getFieldWithDefault(msg, 6, ""),
    paymentname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    paymentaccountnumberormomonumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    transstatus: jspb.Message.getFieldWithDefault(msg, 9, ""),
    paymentmethod: jspb.Message.getFieldWithDefault(msg, 10, ""),
    agentname: jspb.Message.getFieldWithDefault(msg, 11, ""),
    cardname: jspb.Message.getFieldWithDefault(msg, 12, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.AgentTransactionData}
 */
proto.AgentMobileWeb.AgentTransactionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.AgentTransactionData;
  return proto.AgentMobileWeb.AgentTransactionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.AgentTransactionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.AgentTransactionData}
 */
proto.AgentMobileWeb.AgentTransactionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMembername(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTransamount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentname(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentaccountnumberormomonumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransstatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentmethod(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentname(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardname(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.AgentTransactionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.AgentTransactionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.AgentTransactionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMembername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransamount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCardid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPaymentname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPaymentaccountnumberormomonumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTransstatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPaymentmethod();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAgentname();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCardname();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string transactionId = 1;
 * @return {string}
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentTransactionData} returns this
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string memberName = 2;
 * @return {string}
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.getMembername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentTransactionData} returns this
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.setMembername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double transAmount = 3;
 * @return {number}
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.getTransamount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.AgentMobileWeb.AgentTransactionData} returns this
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.setTransamount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string cardId = 4;
 * @return {string}
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.getCardid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentTransactionData} returns this
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.setCardid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentTransactionData} returns this
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string date = 6;
 * @return {string}
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentTransactionData} returns this
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string paymentName = 7;
 * @return {string}
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.getPaymentname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentTransactionData} returns this
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.setPaymentname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string paymentAccountNumberOrMomoNumber = 8;
 * @return {string}
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.getPaymentaccountnumberormomonumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentTransactionData} returns this
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.setPaymentaccountnumberormomonumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string transStatus = 9;
 * @return {string}
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.getTransstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentTransactionData} returns this
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.setTransstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string paymentMethod = 10;
 * @return {string}
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.getPaymentmethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentTransactionData} returns this
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.setPaymentmethod = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string agentName = 11;
 * @return {string}
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.getAgentname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentTransactionData} returns this
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.setAgentname = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string cardName = 12;
 * @return {string}
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.getCardname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentTransactionData} returns this
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.setCardname = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string mobileNumber = 13;
 * @return {string}
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentTransactionData} returns this
 */
proto.AgentMobileWeb.AgentTransactionData.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AgentMobileWeb.TransactionListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.TransactionListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.TransactionListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.TransactionListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.TransactionListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionlistList: jspb.Message.toObjectList(msg.getTransactionlistList(),
    proto.AgentMobileWeb.AgentTransactionData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.TransactionListResponse}
 */
proto.AgentMobileWeb.TransactionListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.TransactionListResponse;
  return proto.AgentMobileWeb.TransactionListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.TransactionListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.TransactionListResponse}
 */
proto.AgentMobileWeb.TransactionListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.AgentMobileWeb.AgentTransactionData;
      reader.readMessage(value,proto.AgentMobileWeb.AgentTransactionData.deserializeBinaryFromReader);
      msg.addTransactionlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.TransactionListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.TransactionListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.TransactionListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.TransactionListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.AgentMobileWeb.AgentTransactionData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AgentTransactionData transactionList = 1;
 * @return {!Array<!proto.AgentMobileWeb.AgentTransactionData>}
 */
proto.AgentMobileWeb.TransactionListResponse.prototype.getTransactionlistList = function() {
  return /** @type{!Array<!proto.AgentMobileWeb.AgentTransactionData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AgentMobileWeb.AgentTransactionData, 1));
};


/**
 * @param {!Array<!proto.AgentMobileWeb.AgentTransactionData>} value
 * @return {!proto.AgentMobileWeb.TransactionListResponse} returns this
*/
proto.AgentMobileWeb.TransactionListResponse.prototype.setTransactionlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.AgentMobileWeb.AgentTransactionData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AgentMobileWeb.AgentTransactionData}
 */
proto.AgentMobileWeb.TransactionListResponse.prototype.addTransactionlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.AgentMobileWeb.AgentTransactionData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AgentMobileWeb.TransactionListResponse} returns this
 */
proto.AgentMobileWeb.TransactionListResponse.prototype.clearTransactionlistList = function() {
  return this.setTransactionlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.DonationCollectedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.DonationCollectedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.DonationCollectedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.DonationCollectedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totaldonation: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.DonationCollectedResponse}
 */
proto.AgentMobileWeb.DonationCollectedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.DonationCollectedResponse;
  return proto.AgentMobileWeb.DonationCollectedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.DonationCollectedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.DonationCollectedResponse}
 */
proto.AgentMobileWeb.DonationCollectedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotaldonation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.DonationCollectedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.DonationCollectedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.DonationCollectedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.DonationCollectedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotaldonation();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double totalDonation = 1;
 * @return {number}
 */
proto.AgentMobileWeb.DonationCollectedResponse.prototype.getTotaldonation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.AgentMobileWeb.DonationCollectedResponse} returns this
 */
proto.AgentMobileWeb.DonationCollectedResponse.prototype.setTotaldonation = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.AgentEmptyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.AgentEmptyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.AgentEmptyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.AgentEmptyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.AgentEmptyRequest}
 */
proto.AgentMobileWeb.AgentEmptyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.AgentEmptyRequest;
  return proto.AgentMobileWeb.AgentEmptyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.AgentEmptyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.AgentEmptyRequest}
 */
proto.AgentMobileWeb.AgentEmptyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.AgentEmptyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.AgentEmptyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.AgentEmptyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.AgentEmptyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.PasswordResetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.PasswordResetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.PasswordResetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.PasswordResetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    updatedby: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.PasswordResetRequest}
 */
proto.AgentMobileWeb.PasswordResetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.PasswordResetRequest;
  return proto.AgentMobileWeb.PasswordResetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.PasswordResetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.PasswordResetRequest}
 */
proto.AgentMobileWeb.PasswordResetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedby(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.PasswordResetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.PasswordResetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.PasswordResetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.PasswordResetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUpdatedby();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string agentId = 1;
 * @return {string}
 */
proto.AgentMobileWeb.PasswordResetRequest.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.PasswordResetRequest} returns this
 */
proto.AgentMobileWeb.PasswordResetRequest.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mobileNumber = 2;
 * @return {string}
 */
proto.AgentMobileWeb.PasswordResetRequest.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.PasswordResetRequest} returns this
 */
proto.AgentMobileWeb.PasswordResetRequest.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string updatedBy = 3;
 * @return {string}
 */
proto.AgentMobileWeb.PasswordResetRequest.prototype.getUpdatedby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.PasswordResetRequest} returns this
 */
proto.AgentMobileWeb.PasswordResetRequest.prototype.setUpdatedby = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.AgentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.AgentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.AgentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.AgentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pin: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.AgentResponse}
 */
proto.AgentMobileWeb.AgentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.AgentResponse;
  return proto.AgentMobileWeb.AgentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.AgentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.AgentResponse}
 */
proto.AgentMobileWeb.AgentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.AgentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.AgentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.AgentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.AgentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.AgentMobileWeb.AgentResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AgentMobileWeb.AgentResponse} returns this
 */
proto.AgentMobileWeb.AgentResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.AgentMobileWeb.AgentResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentResponse} returns this
 */
proto.AgentMobileWeb.AgentResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pin = 3;
 * @return {string}
 */
proto.AgentMobileWeb.AgentResponse.prototype.getPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentResponse} returns this
 */
proto.AgentMobileWeb.AgentResponse.prototype.setPin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.AgentUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.AgentUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.AgentUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.AgentUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    agentname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    updatedby: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.AgentUpdateRequest}
 */
proto.AgentMobileWeb.AgentUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.AgentUpdateRequest;
  return proto.AgentMobileWeb.AgentUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.AgentUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.AgentUpdateRequest}
 */
proto.AgentMobileWeb.AgentUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedby(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.AgentUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.AgentUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.AgentUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.AgentUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAgentname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUpdatedby();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string agentId = 1;
 * @return {string}
 */
proto.AgentMobileWeb.AgentUpdateRequest.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentUpdateRequest} returns this
 */
proto.AgentMobileWeb.AgentUpdateRequest.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mobileNumber = 2;
 * @return {string}
 */
proto.AgentMobileWeb.AgentUpdateRequest.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentUpdateRequest} returns this
 */
proto.AgentMobileWeb.AgentUpdateRequest.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string agentName = 3;
 * @return {string}
 */
proto.AgentMobileWeb.AgentUpdateRequest.prototype.getAgentname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentUpdateRequest} returns this
 */
proto.AgentMobileWeb.AgentUpdateRequest.prototype.setAgentname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string updatedBy = 4;
 * @return {string}
 */
proto.AgentMobileWeb.AgentUpdateRequest.prototype.getUpdatedby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentUpdateRequest} returns this
 */
proto.AgentMobileWeb.AgentUpdateRequest.prototype.setUpdatedby = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.AgentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.AgentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.AgentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.AgentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    agentname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdby: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.AgentRequest}
 */
proto.AgentMobileWeb.AgentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.AgentRequest;
  return proto.AgentMobileWeb.AgentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.AgentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.AgentRequest}
 */
proto.AgentMobileWeb.AgentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedby(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.AgentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.AgentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.AgentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.AgentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAgentname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedby();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string mobileNumber = 1;
 * @return {string}
 */
proto.AgentMobileWeb.AgentRequest.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentRequest} returns this
 */
proto.AgentMobileWeb.AgentRequest.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string agentName = 2;
 * @return {string}
 */
proto.AgentMobileWeb.AgentRequest.prototype.getAgentname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentRequest} returns this
 */
proto.AgentMobileWeb.AgentRequest.prototype.setAgentname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string createdBy = 3;
 * @return {string}
 */
proto.AgentMobileWeb.AgentRequest.prototype.getCreatedby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.AgentRequest} returns this
 */
proto.AgentMobileWeb.AgentRequest.prototype.setCreatedby = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.Agent.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.Agent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.Agent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.Agent.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    agentname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.Agent}
 */
proto.AgentMobileWeb.Agent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.Agent;
  return proto.AgentMobileWeb.Agent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.Agent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.Agent}
 */
proto.AgentMobileWeb.Agent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.Agent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.Agent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.Agent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.Agent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAgentname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string agentId = 1;
 * @return {string}
 */
proto.AgentMobileWeb.Agent.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.Agent} returns this
 */
proto.AgentMobileWeb.Agent.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string agentName = 2;
 * @return {string}
 */
proto.AgentMobileWeb.Agent.prototype.getAgentname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.Agent} returns this
 */
proto.AgentMobileWeb.Agent.prototype.setAgentname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mobileNumber = 3;
 * @return {string}
 */
proto.AgentMobileWeb.Agent.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.Agent} returns this
 */
proto.AgentMobileWeb.Agent.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AgentMobileWeb.ListOfAgentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.ListOfAgentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.ListOfAgentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.ListOfAgentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.ListOfAgentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listofagentsList: jspb.Message.toObjectList(msg.getListofagentsList(),
    proto.AgentMobileWeb.Agent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.ListOfAgentsResponse}
 */
proto.AgentMobileWeb.ListOfAgentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.ListOfAgentsResponse;
  return proto.AgentMobileWeb.ListOfAgentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.ListOfAgentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.ListOfAgentsResponse}
 */
proto.AgentMobileWeb.ListOfAgentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.AgentMobileWeb.Agent;
      reader.readMessage(value,proto.AgentMobileWeb.Agent.deserializeBinaryFromReader);
      msg.addListofagents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.ListOfAgentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.ListOfAgentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.ListOfAgentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.ListOfAgentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListofagentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.AgentMobileWeb.Agent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Agent listOfAgents = 1;
 * @return {!Array<!proto.AgentMobileWeb.Agent>}
 */
proto.AgentMobileWeb.ListOfAgentsResponse.prototype.getListofagentsList = function() {
  return /** @type{!Array<!proto.AgentMobileWeb.Agent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AgentMobileWeb.Agent, 1));
};


/**
 * @param {!Array<!proto.AgentMobileWeb.Agent>} value
 * @return {!proto.AgentMobileWeb.ListOfAgentsResponse} returns this
*/
proto.AgentMobileWeb.ListOfAgentsResponse.prototype.setListofagentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.AgentMobileWeb.Agent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AgentMobileWeb.Agent}
 */
proto.AgentMobileWeb.ListOfAgentsResponse.prototype.addListofagents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.AgentMobileWeb.Agent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AgentMobileWeb.ListOfAgentsResponse} returns this
 */
proto.AgentMobileWeb.ListOfAgentsResponse.prototype.clearListofagentsList = function() {
  return this.setListofagentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.TotalTransactionCommissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.TotalTransactionCommissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.TotalTransactionCommissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.TotalTransactionCommissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startdate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.TotalTransactionCommissionRequest}
 */
proto.AgentMobileWeb.TotalTransactionCommissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.TotalTransactionCommissionRequest;
  return proto.AgentMobileWeb.TotalTransactionCommissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.TotalTransactionCommissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.TotalTransactionCommissionRequest}
 */
proto.AgentMobileWeb.TotalTransactionCommissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.TotalTransactionCommissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.TotalTransactionCommissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.TotalTransactionCommissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.TotalTransactionCommissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string startDate = 1;
 * @return {string}
 */
proto.AgentMobileWeb.TotalTransactionCommissionRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.TotalTransactionCommissionRequest} returns this
 */
proto.AgentMobileWeb.TotalTransactionCommissionRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string endDate = 2;
 * @return {string}
 */
proto.AgentMobileWeb.TotalTransactionCommissionRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.TotalTransactionCommissionRequest} returns this
 */
proto.AgentMobileWeb.TotalTransactionCommissionRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.TotalTransactionAndCommission.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.TotalTransactionAndCommission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.TotalTransactionAndCommission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.TotalTransactionAndCommission.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totaltransaction: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalcommission: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.TotalTransactionAndCommission}
 */
proto.AgentMobileWeb.TotalTransactionAndCommission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.TotalTransactionAndCommission;
  return proto.AgentMobileWeb.TotalTransactionAndCommission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.TotalTransactionAndCommission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.TotalTransactionAndCommission}
 */
proto.AgentMobileWeb.TotalTransactionAndCommission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentname(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotaltransaction(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalcommission(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.TotalTransactionAndCommission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.TotalTransactionAndCommission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.TotalTransactionAndCommission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.TotalTransactionAndCommission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotaltransaction();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalcommission();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string agentName = 1;
 * @return {string}
 */
proto.AgentMobileWeb.TotalTransactionAndCommission.prototype.getAgentname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.TotalTransactionAndCommission} returns this
 */
proto.AgentMobileWeb.TotalTransactionAndCommission.prototype.setAgentname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double totalTransaction = 2;
 * @return {number}
 */
proto.AgentMobileWeb.TotalTransactionAndCommission.prototype.getTotaltransaction = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.AgentMobileWeb.TotalTransactionAndCommission} returns this
 */
proto.AgentMobileWeb.TotalTransactionAndCommission.prototype.setTotaltransaction = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double totalCommission = 3;
 * @return {number}
 */
proto.AgentMobileWeb.TotalTransactionAndCommission.prototype.getTotalcommission = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.AgentMobileWeb.TotalTransactionAndCommission} returns this
 */
proto.AgentMobileWeb.TotalTransactionAndCommission.prototype.setTotalcommission = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string mobileNumber = 4;
 * @return {string}
 */
proto.AgentMobileWeb.TotalTransactionAndCommission.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.TotalTransactionAndCommission} returns this
 */
proto.AgentMobileWeb.TotalTransactionAndCommission.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AgentMobileWeb.TotalTransactionCommissionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.TotalTransactionCommissionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.TotalTransactionCommissionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.TotalTransactionCommissionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.TotalTransactionCommissionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentdataList: jspb.Message.toObjectList(msg.getAgentdataList(),
    proto.AgentMobileWeb.TotalTransactionAndCommission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.TotalTransactionCommissionResponse}
 */
proto.AgentMobileWeb.TotalTransactionCommissionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.TotalTransactionCommissionResponse;
  return proto.AgentMobileWeb.TotalTransactionCommissionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.TotalTransactionCommissionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.TotalTransactionCommissionResponse}
 */
proto.AgentMobileWeb.TotalTransactionCommissionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.AgentMobileWeb.TotalTransactionAndCommission;
      reader.readMessage(value,proto.AgentMobileWeb.TotalTransactionAndCommission.deserializeBinaryFromReader);
      msg.addAgentdata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.TotalTransactionCommissionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.TotalTransactionCommissionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.TotalTransactionCommissionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.TotalTransactionCommissionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentdataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.AgentMobileWeb.TotalTransactionAndCommission.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TotalTransactionAndCommission agentData = 1;
 * @return {!Array<!proto.AgentMobileWeb.TotalTransactionAndCommission>}
 */
proto.AgentMobileWeb.TotalTransactionCommissionResponse.prototype.getAgentdataList = function() {
  return /** @type{!Array<!proto.AgentMobileWeb.TotalTransactionAndCommission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AgentMobileWeb.TotalTransactionAndCommission, 1));
};


/**
 * @param {!Array<!proto.AgentMobileWeb.TotalTransactionAndCommission>} value
 * @return {!proto.AgentMobileWeb.TotalTransactionCommissionResponse} returns this
*/
proto.AgentMobileWeb.TotalTransactionCommissionResponse.prototype.setAgentdataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.AgentMobileWeb.TotalTransactionAndCommission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AgentMobileWeb.TotalTransactionAndCommission}
 */
proto.AgentMobileWeb.TotalTransactionCommissionResponse.prototype.addAgentdata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.AgentMobileWeb.TotalTransactionAndCommission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AgentMobileWeb.TotalTransactionCommissionResponse} returns this
 */
proto.AgentMobileWeb.TotalTransactionCommissionResponse.prototype.clearAgentdataList = function() {
  return this.setAgentdataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.TransactionCommissionDetailData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.TransactionCommissionDetailData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    agentname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totaldonationmobilized: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalcommission: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    agentmobilenumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    donormobilenumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    donorname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    paymentaccountnumberormomonumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    paymentmethod: jspb.Message.getFieldWithDefault(msg, 9, ""),
    memberid: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.TransactionCommissionDetailData}
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.TransactionCommissionDetailData;
  return proto.AgentMobileWeb.TransactionCommissionDetailData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.TransactionCommissionDetailData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.TransactionCommissionDetailData}
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentname(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotaldonationmobilized(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalcommission(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentmobilenumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDonormobilenumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDonorname(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentaccountnumberormomonumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentmethod(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.TransactionCommissionDetailData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.TransactionCommissionDetailData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAgentname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotaldonationmobilized();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTotalcommission();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getAgentmobilenumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDonormobilenumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDonorname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPaymentaccountnumberormomonumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPaymentmethod();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string agentId = 1;
 * @return {string}
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.TransactionCommissionDetailData} returns this
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string agentName = 2;
 * @return {string}
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.getAgentname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.TransactionCommissionDetailData} returns this
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.setAgentname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double totalDonationMobilized = 3;
 * @return {number}
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.getTotaldonationmobilized = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.AgentMobileWeb.TransactionCommissionDetailData} returns this
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.setTotaldonationmobilized = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double totalCommission = 4;
 * @return {number}
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.getTotalcommission = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.AgentMobileWeb.TransactionCommissionDetailData} returns this
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.setTotalcommission = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string agentMobileNumber = 5;
 * @return {string}
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.getAgentmobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.TransactionCommissionDetailData} returns this
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.setAgentmobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string donorMobileNumber = 6;
 * @return {string}
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.getDonormobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.TransactionCommissionDetailData} returns this
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.setDonormobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string donorName = 7;
 * @return {string}
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.getDonorname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.TransactionCommissionDetailData} returns this
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.setDonorname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string paymentAccountNumberOrMomoNumber = 8;
 * @return {string}
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.getPaymentaccountnumberormomonumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.TransactionCommissionDetailData} returns this
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.setPaymentaccountnumberormomonumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string paymentMethod = 9;
 * @return {string}
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.getPaymentmethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.TransactionCommissionDetailData} returns this
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.setPaymentmethod = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string memberId = 10;
 * @return {string}
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.AgentMobileWeb.TransactionCommissionDetailData} returns this
 */
proto.AgentMobileWeb.TransactionCommissionDetailData.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AgentMobileWeb.TransactionCommissionDetailResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentMobileWeb.TransactionCommissionDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentMobileWeb.TransactionCommissionDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentMobileWeb.TransactionCommissionDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.TransactionCommissionDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responseList: jspb.Message.toObjectList(msg.getResponseList(),
    proto.AgentMobileWeb.TransactionCommissionDetailData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentMobileWeb.TransactionCommissionDetailResponse}
 */
proto.AgentMobileWeb.TransactionCommissionDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentMobileWeb.TransactionCommissionDetailResponse;
  return proto.AgentMobileWeb.TransactionCommissionDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentMobileWeb.TransactionCommissionDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentMobileWeb.TransactionCommissionDetailResponse}
 */
proto.AgentMobileWeb.TransactionCommissionDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.AgentMobileWeb.TransactionCommissionDetailData;
      reader.readMessage(value,proto.AgentMobileWeb.TransactionCommissionDetailData.deserializeBinaryFromReader);
      msg.addResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentMobileWeb.TransactionCommissionDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentMobileWeb.TransactionCommissionDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentMobileWeb.TransactionCommissionDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentMobileWeb.TransactionCommissionDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.AgentMobileWeb.TransactionCommissionDetailData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TransactionCommissionDetailData response = 1;
 * @return {!Array<!proto.AgentMobileWeb.TransactionCommissionDetailData>}
 */
proto.AgentMobileWeb.TransactionCommissionDetailResponse.prototype.getResponseList = function() {
  return /** @type{!Array<!proto.AgentMobileWeb.TransactionCommissionDetailData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AgentMobileWeb.TransactionCommissionDetailData, 1));
};


/**
 * @param {!Array<!proto.AgentMobileWeb.TransactionCommissionDetailData>} value
 * @return {!proto.AgentMobileWeb.TransactionCommissionDetailResponse} returns this
*/
proto.AgentMobileWeb.TransactionCommissionDetailResponse.prototype.setResponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.AgentMobileWeb.TransactionCommissionDetailData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AgentMobileWeb.TransactionCommissionDetailData}
 */
proto.AgentMobileWeb.TransactionCommissionDetailResponse.prototype.addResponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.AgentMobileWeb.TransactionCommissionDetailData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AgentMobileWeb.TransactionCommissionDetailResponse} returns this
 */
proto.AgentMobileWeb.TransactionCommissionDetailResponse.prototype.clearResponseList = function() {
  return this.setResponseList([]);
};


/**
 * @enum {number}
 */
proto.AgentMobileWeb.AcctCreationStatus = {
  COMPLETE: 0,
  INCOMPLETE: 1,
  DOES_NOT_EXIST: 2
};

/**
 * @enum {number}
 */
proto.AgentMobileWeb.TransStatus = {
  SUCCESSFUL: 0,
  PENDING: 1,
  FAILED: 2,
  ALL: 3
};

goog.object.extend(exports, proto.AgentMobileWeb);
